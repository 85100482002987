import React, { useState, useEffect } from 'react';
import { Form, Button, Upload, Row, Col, Input, message } from 'antd';
import { UpuploadloadOutlined, InboxOutlined } from '@ant-design/icons';

import { productUpload } from '../../../services/api';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;
`
const CustomForm = styled(Form)`
  .ant-input, .ant-picker {
    border-radius: 5px;
  }
  
  .ant-upload{
    width: 100%;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    display: flex;
    justify-content: end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .mt30 {
    margin-top: 30px;
  }

  .mt0 {
    .ant-row {
      margin-bottom: 0px;
    }
  }
`
const Box = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px dashed #d9d9d9;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  p {
    color: #6C6C6C
  }
`


const FileModalContainer = ({s_productData, set_s_showFileModal, s_FileModalTitle, call_getAllProduct}) => {

  const [form] = Form.useForm();
  const [s_file, set_s_file] = useState({})

  useEffect(() => {
    form.resetFields();
  }, [form, s_productData]);

  useEffect(() => {

    return () => {
      call_getAllProduct()
    }
  },[])

  const normFile = (e) => {
  
    if (Array.isArray(e)) {
      return e;
    }
  
    return e && e.fileList;
  };

  const onSubmit = async values => {
    
    // 沒有上傳就點確認送出
    if(Object.keys(s_file).length === 0) {
      message.warning(`請先選擇${s_FileModalTitle.toUpperCase()}檔案`);
      return;
    } 

    // 上傳檔案不可以用一般傳資料的方式，且 content-type 必須是 'multipart/form-data'
    const formData = new FormData();
    formData.append('file', s_file);
    formData.append('PN', values.PN);
    formData.append('pdftype', s_FileModalTitle);

    console.log('formData = ', formData)

    // 有選檔案
    if(Object.keys(s_file).length > 0) {

      const res = await productUpload(formData);
      
      res.status === true ? message.success(res.msg) : message.error(res.msg);

      set_s_showFileModal(false);
    }
    
  }

  const props = {
    listType: 'picture',
    beforeUpload: (file, fileList) => {
      // Access file content here and do something with it
      //console.log('aaa = ', file);

      // Prevent upload
      return false;
    },

    onChange: ({file, fileList}) => {
      if(fileList.length === 1) {
        set_s_file(file)
      } else {
        set_s_file({})
      }
      
    },
  };

  const handleCancel = () => {
    set_s_showFileModal(false)
  }

  return (
    <Wrapper>
      <CustomForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            PN: s_productData ? s_productData.PN : '',  // 料號
            pdtNM: s_productData ? s_productData.pdtNM : '',  // 品名
          }}
        >
          <Row >
            <Col span={12}>{/* style={{backgroundColor: 'red'}} */}
              <Form.Item 
                labelCol={{span:6}}
                wrapperCol={{span:22}} 
                label={<label style={{color: '#6C6C6C'}}>料號</label>}
                name="PN"
              >
                <Input disabled name='PN' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                labelCol={{offset:2 ,span:6}}
                wrapperCol={{offset:2 ,span:22}} 
                label={<label style={{color: '#6C6C6C'}}>品名</label>}
                name="pdtNM"
              >
                <Input disabled name='pdtNM' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} >
              <Form.Item name='data' valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload {...props}  >
                {Object.keys(s_file).length === 0 ? (
                  <Box>
                    <InboxOutlined style={{fontSize: '30px', color: '#40A9FF'}} />
                    <p>點擊此處上傳檔案</p>
                  </Box>
                ) : null}
              </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt30">
            <Col offset={16} span={8}>
              <div className='mt0' style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{ borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>

       </CustomForm>
      
    </Wrapper>
    
  )
};

export default FileModalContainer;