import React, { useState } from 'react';
import { message } from 'antd';

import HD from './HD';
import TB from './TB';

message.config({ duration: 1.5 });

export const DataContext = React.createContext();

const OutBarcodeSearch = () => {

  const [ c_lightData, set_c_lightData ] = useState({});   // 要亮起來的資料

  return (
    <DataContext.Provider value={{c_lightData, set_c_lightData}}>
      <HD />
    </DataContext.Provider>
  )
};

export default OutBarcodeSearch;