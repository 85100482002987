import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, message, Select, Radio, Divider } from 'antd';
import { getAllStation, getAllPS, addLine, modifyLine } from '../../../services/api';

message.config({
  duration: 1.5
})

const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_s_showModal, s_LineData}) => {

  const [form] = Form.useForm();

  const [s_AllStation, set_s_AllStation] = useState([]);  // 所有工站資訊
  const [s_AllPS, set_s_AllPS] = useState([]);            // 所有車間資訊

  useEffect(() => {
    const call_getAllStation = async () => {
      const res = await getAllStation();
      if(res.status) {
        set_s_AllStation(res.data)
      }
    };
    const call_getAllPS = async () => {
      const res = await getAllPS();
      if(res.status) {
        set_s_AllPS(res.data)
      }
    };
    call_getAllStation()
    call_getAllPS()
  },[]);

  useEffect(() => {
    form.resetFields()
  }, [form, s_LineData]);

  const onSubmit = async values => {

    const type = Object.keys(s_LineData).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      // 新增工線資料
      const res = await addLine(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };

    if(type === 'modify') {
      // 修改工線資料
      console.log('要傳給後端的資料 = ', values)
      const res = await modifyLine(values.wlID, values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  return (
    <>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          wsID: Object.keys(s_LineData).length > 0 ? s_LineData.wsID : null,    // 站別
          psID: Object.keys(s_LineData).length > 0 ? s_LineData.psID : null,    // 車間
          wlID: Object.keys(s_LineData).length > 0 ? s_LineData.wlID : '',    // 線別
          wlNM: Object.keys(s_LineData).length > 0 ? s_LineData.wlNM : '',    // 工線名稱
          brand: Object.keys(s_LineData).length > 0 ? s_LineData.brand : '',  // 品牌
          model: Object.keys(s_LineData).length > 0 ? s_LineData.model : '',  // 型號

          MC: Object.keys(s_LineData).length > 0 ? s_LineData.MC : 'N',          // 設定物控作業
          QC: Object.keys(s_LineData).length > 0 ? s_LineData.QC : 'N',          // 設定質控作業
          WOC: Object.keys(s_LineData).length > 0 ? s_LineData.WOC : 'N',        // 設定生產控制
          SFC: Object.keys(s_LineData).length > 0 ? s_LineData.SFC : 'N',        // 設定工站報工
          OUT: Object.keys(s_LineData).length > 0 ? s_LineData.OUT : 'N',        // 設定委外報工
          PDT: Object.keys(s_LineData).length > 0 ? s_LineData.PDT : 'N',        // 設定生產查詢
        }}
      >
        <Row>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>工線ID</label>}
              name="wlID"
              wrapperCol={{span:20}}
              rules={[{ required: true, message: '請填寫工線ID!' }]}
            >
                <Input disabled={Object.keys(s_LineData).length === 0 ? false : true} name='wlID' placeholder="請填寫工線ID" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>工線名稱</label>}
              name="wlNM"
              wrapperCol={{span:20}}
              rules={[{ required: true, message: '請填寫工線名稱!' }]}
            >
              <Input name='wlNM' placeholder="請填寫工線名稱" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>工站名稱</label>}
              name="wsID"
              wrapperCol={{span:20}}
              rules={[{ required: true, message: '請選擇工站!' }]}
            >
                {/* <Input disabled={Object.keys(s_LineData).length === 0 ? false : true} name='wsID' placeholder="請填寫工站ID" /> */}
              <Select placeholder="請選擇工站" allowClear >
                {s_AllStation.length > 0 && s_AllStation.map(item => {
                  return <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>
              })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>品牌</label>}
              name="brand"
              wrapperCol={{span:20}}
              rules={[{ required: true, message: '請填寫品牌名稱!' }]}
            >
              <Input name='brand' placeholder="請填寫品牌名稱" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>型號</label>}
              name="model"
              wrapperCol={{span:20}}
              rules={[{ required: true, message: '請填寫型號名稱!' }]}
            >
              <Input name='model' placeholder="請填寫型號名稱" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>車間名稱</label>}
              name="psID"
              wrapperCol={{span:20}}
            >
                {/* <Input disabled={Object.keys(s_LineData).length === 0 ? false : true} name='psNM' placeholder="請填寫車間名稱" /> */}
              <Select placeholder="請選擇車間" allowClear >
                {s_AllPS.length > 0 && s_AllPS.map(item => {
                  return <Select.Option key={item.psID} value={item.psID}>{item.psNM}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{margin: '6px 6px 24px 6px'}} />

        <Row>
          <Col span={8}>
            <Form.Item 
              name='MC'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定物控作業:</label>} 
            >
              <Radio.Group className="center" name="MC">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='QC'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定質控作業:</label>} 
            >
              <Radio.Group className="center" name="QC">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='WOC'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定生產控制:</label>} 
            >
              <Radio.Group className="center" name="WOC">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item 
              name='SFC'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定工站報工:</label>} 
            >
              <Radio.Group className="center" name="WOC">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='OUT'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定委外報工:</label>} 
            >
              <Radio.Group className="center" name="OUT">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='PDT'
              labelCol={{offset:2}}
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定生產查詢:</label>} 
            >
              <Radio.Group className="center" name="PDT">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        
        <Row>
          <Col span={24}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};
export default ModalContainer