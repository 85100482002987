import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, Select, InputNumber, Space, Divider } from "antd";
import { EditOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import Cookies from "universal-cookie";
import moment from "moment";
import "./PrintForm.css";
import MyIcon from "../../Icon/pageIcon";
import { getOneStaff } from "../../../services/apis/printer";

const FormTemplate = ({ thisData, className }) => {
  const cookies = new Cookies();
  return (
    <div className={`${className} Wraper`}>
      <div className="Header pr-5 pl-5">
        <div className="Info-text">結&nbsp;單&nbsp;記&nbsp;錄&nbsp;表</div>
        <div className="woN">
          <div className="h-30 font-17 pr-10">工單號 : </div>
          <div className="h-30 font-17">{thisData.woN}</div>
        </div>
      </div>

      <div className="flex-c">
        <div className="flex-r">
          <div className="title w-16 h-50 flex-center border-t border-l border-r border-b">
            料號
          </div>
          <div className="value w-33_5 h-50 pl-15 flex-left border-t border-r border-b font-17">
            {thisData.PN}
          </div>
          <div className="title w-15 h-50 flex-center border-t border-b border-r">
            工站
          </div>
          <div className="value w-35 h-50 flex-center border-t border-r border-b font-17">
            {cookies.get("wsID") + "_" + cookies.get("wsNM")}
          </div>
        </div>

        <div className="flex-r">
          <div className="title w-16 h-50 flex-center border-l border-r border-b">
            品名
          </div>
          <div className="value w-33_5 h-50 pl-15 flex-left border-r border-b font-13">
            {thisData.pdtNM}
          </div>
          <div className="title w-15 h-50 flex-center border-r border-b flex-center">
            工線
          </div>
          <div className="value w-35 h-50 flex-center border-r border-b font-17">
            {cookies.get("wlID") + "_" + cookies.get("wlNM")}
          </div>
        </div>
      </div>

      <div className="HeaderInfo">
        <div className="flex-c w-16">
          <div className="title w-100 h-50 flex-center border-l border-r border-b">
            報開工時間
          </div>
          <div className="title w-100 h-50 flex-center border-l border-r border-b">
            報完工時間
          </div>
          <div className="title w-100 h-50 flex-center border-l border-r border-b">
            總生產時間
          </div>
        </div>

        <div className="flex-c w-33_5">
          <div className="h-50 border-b border-r pl-15 value font-17 flex-center">
            {moment(thisData.openT).format("yyyy/MM/DD HH:mm")}
          </div>
          <div className="h-50 border-b border-r pl-15 value font-17 flex-center">
            {moment(thisData.closeT).format("yyyy/MM/DD HH:mm")}
          </div>
          <div className="h-50 border-b border-r pl-15 value font-17 flex-center">
            {`${thisData.totalDay[0]}天${thisData.totalDay[1]}時${thisData.totalDay[2]}分${thisData.totalDay[3]}秒`}
          </div>
        </div>

        <div className="w-50 QRCode border-b border-r">
          <div className="title flex-col-c pl-40">
            途程碼
            <div className="value">
              {" "}
              {thisData.woType}-{thisData.wpcSet}-{thisData.wpcSN}-
              {thisData.addSN}&emsp;
              {thisData.prefix}-{thisData.suffix}-{thisData.offfix}-
              {thisData.sqc}
            </div>
          </div>

          <div>
            <QRCode
              id={thisData.UUID}
              value={`{"UUID":"${thisData.UUID}","woN":"${thisData.woN}"}`} //value引數為生成二維碼的連結 我這裡是由後端返回
              size={80} //二維碼的寬高尺寸
              fgColor="#000000" //二維碼的顏色
            />
          </div>
        </div>
      </div>

      <div className="pl-5 title1">物控盤料</div>
      <div className="flex-r w-100">
        <div className="flex-c w-16">
          <div className="title h-50 flex-center border-r border-b border-l border-t">
            投產量
          </div>
          <div className="h-50 flex-center border-r border-b border-l font-17">
            {thisData.inC}
          </div>
          <div className="title h-50 flex-center border-r border-b border-l">
            品保複判
            <EditOutlined className="pl-5" />
          </div>
        </div>

        <div className="flex-c w-16">
          <div className="title h-50 flex-center border-r border-b border-t">
            良品數
            <EditOutlined className="pl-5" />
          </div>
          <div className="h-50 flex-center border-r border-b"></div>
          <div className="h-50 flex-center border-r border-b"></div>
        </div>

        <div className="flex-c w-16">
          <div className="title h-50 flex-center border-r border-b border-t">
            不良品數
            <EditOutlined className="pl-5" />
          </div>
          <div className="h-50 flex-center border-r border-b"></div>
          <div className="h-50 flex-center border-r border-b"></div>
        </div>

        <div className="flex-c w-50">
          {/* <div className='title flex-center border-b border-t border-r h-50 pl-15'>物控盤料</div> */}
          <div className="flex-center w-100">
            <div className="MaterialText font-17 border-r border-b border-t h-120 w-100">
              <span className="pl-40">滿箱數：__________箱__________件/箱</span>
              <span className="pl-40 pt-10">
                零箱數：__________箱____________&nbsp;件
              </span>
              <span className="pl-40 pt-10">
                備&ensp;&ensp;註：__________________________
              </span>
              {/* <span className="pl-40 pt-10">
            __________________________________
          </span> */}
            </div>
          </div>
        </div>
      </div>

      <div className="title1 pl-5 Log border-b">作業紀錄</div>
      <div className="flex-r border-r border-b border-l">
        <div className="flex-c w-20">
          <div className="title flex-center border-r border-b h-50 ">
            作業人員
          </div>
          <div className="flex-col-c h-80 border-r  font-14">
            <div>{thisData.peopleNM}</div>
            <div>{moment(thisData.downT).format("yyyy-MM-DD HH:mm")}</div>
          </div>
        </div>
        <div className="flex-c w-20">
          <div className="title flex-center border-r border-b  h-50" >
            物料盤點
            <EditOutlined className="pl-5" />
          </div>
          <div className="LogData h-80 flex-left border-r pl-15 font-17">
            <span className="pl-70p"></span>
          </div>
        </div>
        <div className="flex-c w-20">
          <div className="title flex-center border-r border-b h-50 border-2-t border-2-l">
            執行［物控］
            <span
              role="img"
              aria-label="edit"
              className="anticon anticon-edit"
            >
              <MyIcon.IconPress />
            </span>
          </div>
          <div className="LogData h-80 flex-left border-r border-2-b font-17 border-2-l">
            <span className="pl-70p"></span>
          </div>
        </div>
        <div className="flex-c w-20">
          <div className="title flex-center border-r border-b h-50 border-2-t">
            執行［質控］
            <span
              role="img"
              aria-label="edit"
              className="anticon anticon-edit"
            >
              <MyIcon.IconPress />
            </span>
          </div>
          <div className="LogData h-80 flex-left border-r  font-17 border-2-b">
            <span className="pl-70p"></span>
          </div>
        </div>
        <div className="flex-c w-20">
          <div className="title flex-center border-b h-50 border-2-t border-2-r">
            執行［過站］
            <span
              role="img"
              aria-label="edit"
              className="anticon anticon-edit"
            >
              <MyIcon.IconPress />
            </span>
          </div>
          <div className="LogData h-80 flex-left pl-15 font-17 border-2-b border-2-r">
            <span className="pl-70p"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrintFormEle = ({ className, setIsModal, thisData }) => {
  const printRef = useRef("");
  const [isPrint, setIsPrint] = useState(true);
  const [printNum, setPrintNum] = useState([1]);
  const [printNumObj, setPrintNumObj] = useState([]);
  const [oneStaffInfo, setOneStaffInfo] = useState({});
  const cookies = new Cookies();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    // content: () => CreatePrintNumObj(printNum),
    bodyClass: "print-style",
    pageStyle: `
    body{
      counter-reset: page-number;  
    }
    @page {
      size: A5 landscape;
      margin-bottom:0px;
    }
    .pointer-box {
      page-break-inside: avoid;
      position: relative;
      min-height: 90vh;
    }

    .Wraper{
      page-break-inside: avoid;
      position: relative;
    }

    .Wraper:after {
      content: "標示單 － "counter(page-number)"/${printNum.length}"; /* 取用該名字的計數器 */
      counter-increment: page-number 1; /* 增加計數器數值 */
      position: absolute;
      right: 5;
      bottom: 10;
      font-size: 20px;
      text-align: center;
    }
    .Wraper:before {
      content: "列印時間：${moment(new Date()).format("yyyy-MM-DD HH:mm:ss")} ${cookies.get("userName")}"; /* 取用該名字的計數器 */
      position: absolute;
      left: 5;
      bottom: 15;
      font-size: 13px;
      text-align: center;
    }
    .template{
      page-break-inside: avoid;
      position: relative;
    }
    .template:after{
      content: "作業單"; 
      position: absolute;
      right: 0;
      bottom: 10;
      font-size: 20px;
      text-align:right;
      width:120px;
      height:30px;
      background: white;
      z-index:99;
    }
  `,
  });

  useEffect(() => {
    const call_getOneStaff = async () => {
      const res = await getOneStaff(thisData);
      setOneStaffInfo(res.data);
    };
    call_getOneStaff();
  }, []);

  useEffect(() => {
    if (!isPrint) {
      setTimeout(() => {
        setIsPrint(true);
      }, [500]);
    }
  }, [isPrint]);

  useEffect(() => {
    const obj = printNum.map((x) => {
      return (
        <FormTemplate
          thisData={{ ...thisData, ...oneStaffInfo }}
          key={Math.random() * 1000}
        />
      );
    });
    setPrintNumObj(obj);
  }, [printNum,oneStaffInfo]);

  return (
    <div className={className}>
      <div className="pointer-box">
        <FormTemplate thisData={{ ...thisData, ...oneStaffInfo }} />
      </div>
      <div className="pointer-box hidden" ref={printRef}>
        {printNumObj}
        <FormTemplate
          thisData={{ ...oneStaffInfo,...thisData }}
          className="template"
        />
      </div>
      <Divider />
      <div className="w-100 align-r">
        <Space>
          <div className="font-17">列印張數</div>
          <InputNumber
            size="large"
            min={1}
            max={20}
            value={printNum.length}
            onChange={(e) => {
              let obj = [];
              for (let i = 0; i < e; i++) {
                obj.push(i);
              }
              setPrintNum(obj);
            }}
          />
          <Button type="primary" size="large" onClick={handlePrint}>
            列印
          </Button>
        </Space>
      </div>
    </div>
  );
};

const PrintForm = styled(PrintFormEle)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .hidden {
    display: none;
  }
  .pointer-box {
    width: 794px;
    /* background-color: #f5e0e0; */
    /* border: 1px solid #000; */
  }
  .ant-table-cell {
    font-size: 12px;
    padding: 4px;
  }
  .ant-table {
    border: none;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #dc10dc00;
  }
  .ant-table-cell {
    background-color: #fafafa;
  }
  .align-r {
    text-align: right;
    margin-right: 15px;
  }
  .w-100 {
    width: 100%;
  }
  .flex-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export default PrintForm;
