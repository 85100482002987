import React, { useState, useEffect } from "react";
import WOI from "../../styled/workOrderInformation";
import useStateRef from "../../utility/useStateRef";
import checkUndefined from "../../utility/checkUndefined";
import { Modal, Button, Row, Col, Tooltip } from "antd";
import { MyThemeH1, MyTriangle } from "../../styled/publicTag";

//Data為資料 state 是否啟動
const WorkOrderInformation = (data) => {
  const [isStart, setIsStart, refIsStart] = useStateRef(false);

  const [workOrder, setWorkOrder, refWorkOrder] = useStateRef({});

  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    if (
      data.data !== undefined &&
      data.data !== null &&
      Object.getOwnPropertyNames(data.data).length !== 0
    ) {
      setWorkOrder(data.data);
      const timer = setInterval(() => {
        //停工或再復工
        if (data.data.lifeF === "2" || data.data.lifeF === "4") {
          refIsStart.current ? setIsStart(false) : setIsStart(true);
        } else {
          setIsStart(false);
        }
      }, 1000);
      //畫面Rendering 完之後塞到dom
      return () => {
        //組件卸載時清除計時器
        clearInterval(timer);
      };
    } else {
      setWorkOrder({});
      setIsStart(false);
    }
  }, [data.data]);

  return (
    <WOI.WOIBox>
      <WOI.WOIMainBoxItem>
        <WOI.WOIMainBoxItemHeader />
        {isStart ? (
          <WOI.WOIStart>主工單號</WOI.WOIStart>
        ) : (
          <WOI.WOIMainBoxItemTitle>主工單號</WOI.WOIMainBoxItemTitle>
        )}
        <WOI.WOIMainBoxItemContent>
          <Tooltip placement="leftBottom" title={checkUndefined(workOrder.woN)}>
            {checkUndefined(workOrder.woN)}
          </Tooltip>
        </WOI.WOIMainBoxItemContent>
      </WOI.WOIMainBoxItem>
      <WOI.WOIMainBoxItem>
        <WOI.WOIMainBoxItemHeader />
        <WOI.WOIMainBoxItemTitle>料號</WOI.WOIMainBoxItemTitle>
        <WOI.WOIMainBoxItemContent>
          <Tooltip placement="leftBottom" title={checkUndefined(workOrder.PN)}>
            {checkUndefined(workOrder.PN)}
          </Tooltip>
        </WOI.WOIMainBoxItemContent>
      </WOI.WOIMainBoxItem>
      <WOI.WOIMainBoxItem>
        <WOI.WOIMainBoxItemHeader />
        <WOI.WOIMainBoxItemTitle>品名</WOI.WOIMainBoxItemTitle>
        <WOI.WOIMainBoxItemContent>
          <Tooltip placement="leftBottom" title={checkUndefined(workOrder.pdtNM)}>
            {checkUndefined(workOrder.pdtNM)}
          </Tooltip>
        </WOI.WOIMainBoxItemContent>
      </WOI.WOIMainBoxItem>
      <WOI.WOIMainBoxItem>
        <WOI.WOIMainBoxItemHeader />
        <WOI.WOIMainBoxItemTitle>單別</WOI.WOIMainBoxItemTitle>
        <WOI.WOIMainBoxItemContent>
          {workOrder.woType === 0
            ? "正常工單"
            : workOrder.woType === "0"
            ? "重工工單"
            : "暫無資料"}
        </WOI.WOIMainBoxItemContent>
      </WOI.WOIMainBoxItem>
      <WOI.WOIMainBoxItem>
        <WOI.WOIMainBoxItemHeader />
        <WOI.WOIMainBoxItemTitle style={{ justifyContent: "space-between" }}>
          投產數量
          <MyTriangle onClick={() => setIsModal(true)} />
        </WOI.WOIMainBoxItemTitle>
        <WOI.WOIMainBoxItemContent>
          {workOrder.inC === undefined ? "暫無數據" : workOrder.inC}
        </WOI.WOIMainBoxItemContent>
      </WOI.WOIMainBoxItem>
      <WOI.WOIMainBoxItem>
        <WOI.WOIMainBoxItemHeader />
        {isStart ? (
          <WOI.WOIStart>完成數量</WOI.WOIStart>
        ) : (
          <WOI.WOIMainBoxItemTitle>完成數量</WOI.WOIMainBoxItemTitle>
        )}
        <WOI.WOIMainBoxItemContent>
          {workOrder.okC === undefined
            ? "暫無數據"
            : workOrder.okC}
        </WOI.WOIMainBoxItemContent>
      </WOI.WOIMainBoxItem>
      <Modal
        title={<MyThemeH1>生產數量詳情</MyThemeH1>}
        visible={isModal}
        onCancel={() => setIsModal(false)}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            type="primary"
            onClick={() => {
              setIsModal(false);
            }}
          >
            確認
          </Button>,
        ]}
        width={"800px"}
        centered
        forceRender
      >
        <div>
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={6}>
              <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                <WOI.WOIMainBoxItemHeader />
                <WOI.WOIMainBoxItemTitle>計畫產量</WOI.WOIMainBoxItemTitle>
                <WOI.WOIMainBoxItemContent>
                  {checkUndefined(workOrder.planQty)}
                </WOI.WOIMainBoxItemContent>
              </WOI.WOIMainBoxItem>
            </Col>
            <Col span={6}>
              <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                <WOI.WOIMainBoxItemHeader />
                <WOI.WOIMainBoxItemTitle>前拆數量</WOI.WOIMainBoxItemTitle>
                <WOI.WOIMainBoxItemContent>
                  {checkUndefined(workOrder.d_planQty)}
                </WOI.WOIMainBoxItemContent>
              </WOI.WOIMainBoxItem>
            </Col>
            <Col span={6}>
              <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                <WOI.WOIMainBoxItemHeader />
                <WOI.WOIMainBoxItemTitle>後拆數量</WOI.WOIMainBoxItemTitle>
                <WOI.WOIMainBoxItemContent>
                  {checkUndefined(workOrder.d_inC)}
                </WOI.WOIMainBoxItemContent>
              </WOI.WOIMainBoxItem>
            </Col>
            <Col span={6}>
              <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                <WOI.WOIMainBoxItemHeader />
                <WOI.WOIMainBoxItemTitle>目標產量</WOI.WOIMainBoxItemTitle>
                <WOI.WOIMainBoxItemContent>
                  {checkUndefined(workOrder.qty)}
                </WOI.WOIMainBoxItemContent>
              </WOI.WOIMainBoxItem>
            </Col>
          </Row>
        </div>
      </Modal>
    </WOI.WOIBox>
  );
};

export default WorkOrderInformation;
