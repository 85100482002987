import React, { useState, useEffect } from 'react';

import { fontSize, MySubtitleH4 } from '../../styled/publicTag';
import { Input , Button, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { getAllPost, getPost, deletePost } from '../../services/api';

import Title from '../../components/Custom/Title';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';

import ModalContainer from './ModalContainer';

import {
  IconTrash,
  IconPen
} from '../../components/Icon';

message.config({
  duration: 1.5
})

const JobTitle = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);  // 是否開啟 dialog
  const [s_JobTitleData, set_s_JobTitleData] = useState({}); // 要被編輯的職稱資料

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 建立新的職稱資料
  const createJobTitle = () => {
    set_s_showModal(true);
  }

  // 搜尋職稱資料
  const onSearch = (value, event) => {
    
    const call_getPost = async (value) => {
      
      if(value === '') return;
      
      const res = await getPost(value);
      
      if(res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }

    }
    call_getPost(value);
  }

  //編輯職稱資料
  const editJobTitle = rowData => {
    set_s_showModal(true);
    set_s_JobTitleData(rowData);
  }

  // 刪除職稱資料
  const removeJobTitle = rowData => {
    Modal.confirm({
      title: '職稱資料刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的職稱資料無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const tmpObj = {
          'postID': rowData.postID
        }
        const res = await deletePost(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllJobTitle();
      },
      cancelText: '取消',
    });
  }

  // 取得所有職稱資料
  const call_getAllJobTitle = async () => {
    try {
      console.log('call_getAllPost')
      const res = s_searchValue === '' ? await getAllPost() : await getPost(s_searchValue);
      console.log(res.data)
      const tmpData = res.data.map((item, index) => {
        return {
          ...item,
          key: index
        }
      });
      set_s_tableData(tmpData)
    } catch (e) {
      console.log('---------- api getAllPost error ----------');
      console.log(e)
    }
  }

  // 取得所有職稱資料
  useEffect(() => { 
    
    if(s_showModal === false) {
      call_getAllJobTitle();
      set_s_JobTitleData({});
    }
  }, [s_showModal]);

  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value)
    if(value === '') {
      try {
        console.log('call_getAllPost')
        const res = await getAllPost();
        console.log(res.data)
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      } catch (e) {
        console.log('---------- api getAllPost error ----------');
        console.log(e)
      }
    }
  }

  const tableColumns = [
    {
      title: '職稱ID',
      dataIndex: 'postID',
      key: 'postID',
      align: 'center',
      // width: 100
    },
    {
      title: '職稱名稱',
      dataIndex: 'postNM',
      key: 'postNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${600}px` : `${1000}px`
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <IconPen className='icon' onClick={() => editJobTitle(rowData)} />
          <IconTrash className='icon' onClick={() => removeJobTitle(rowData)} />
        </div>
      )
    },
  ]

  return (
    <>
      <Title 
        title='職稱資料管理'
        placeholder='請輸入欲查詢職稱資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createJobTitle}
      />
      <CustomTable 
        scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {s_showModal ? (
        <CustomModal 
          width='30%' 
          title={Object.keys(s_JobTitleData).length > 0 ? "修改職稱資料管理" : "新建職稱資料管理"} 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          afterClose={() => {
            set_s_JobTitleData({});
            // call_getAllPS();
          }}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_JobTitleData={s_JobTitleData} />
        </CustomModal>
       ) : null}
      
    </>
  )
}

export default JobTitle;