import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import { MySubtitleH4 } from '../../styled/publicTag';
import { message } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getAllPS, deletePS, getPS } from '../../services/api';

import ModalContainer from './ModalContainer'

import {
  IconTrash,
  IconPen
} from '../../components/Icon';

import Title from '../../components/Custom/Title';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';


message.config({
  duration: 1.5
})

const PSdata = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);  // 是否開啟 dialog
  const [s_PSData, set_s_PSData] = useState({});

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 建立新的車間資料
  const createPS = () => {
    set_s_showModal(true);
  }

  // 搜尋車間資料
  const onSearch = (value, event) => {
    
    const call_getPS = async (value) => {
      
      if(value === '') return;
      
      const res = await getPS(value);
      
      if(res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }

    }
    call_getPS(value);
  }

  //編輯車間資料
  const editPS = rowData => {
    set_s_showModal(true);
    set_s_PSData(rowData);
  }

  // 刪除車間資料
  const removePS = rowData => {
    Modal.confirm({
      title: '車間刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的車間無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const tmpObj = {
          'psID': rowData.psID
        }
        const res = await deletePS(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllPS();
      },
      cancelText: '取消',
    });
  }

  // 取得所有車間資料
  const call_getAllPS = async () => {
    try {
      console.log('call_getAllPS')
      const res = s_searchValue === '' ? await getAllPS() : await getPS(s_searchValue);
      console.log(res.data)
      const tmpData = res.data.map((item, index) => {
        return {
          ...item,
          key: index
        }
      });
      set_s_tableData(tmpData)
    } catch (e) {
      console.log('---------- api getAllStaffPerm error ----------');
      console.log(e)
    }
  }

  // 取得所有車間資料
  useEffect(() => { 
    
    if(s_showModal === false) {
      call_getAllPS();
      set_s_PSData({})
    }
  }, [s_showModal]);

  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value)
    if(value === '') {
      try {
        console.log('call_getAllPS')
        const res = await getAllPS();
        console.log(res.data)
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      } catch (e) {
        console.log('---------- api getAllStaffPerm error ----------');
        console.log(e)
      }
    }
  }

  const tableColumns = [
    {
      title: '車間ID',
      dataIndex: 'psID',
      key: 'psID',
      align: 'center',
      // width: 100
    },
    {
      title: '車間名稱',
      dataIndex: 'psNM',
      key: 'psNM',
      align: 'center',
      // width: innerWidth <= 1440 ? `${600}px` : `${1000}px`
    },
    {
      title: '車間註記',
      dataIndex: 'psNotes',
      key: 'psNotes',
      align: 'center',
      width: innerWidth <= 1440 ? `${1200}px` : `${1000}px`
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <IconPen className='icon' onClick={() => editPS(rowData)} />
          <IconTrash className='icon' onClick={() => removePS(rowData)} />
        </div>
      )
    },
  ]
  return (
    <>
      <Title 
        title='車間資料管理'
        placeholder='請輸入欲查詢車間資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createPS}
      />
      <CustomTable 
        scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {s_showModal ? (
        <CustomModal 
          width='30%' 
          title={Object.keys(s_PSData).length > 0 ? "修改車間資料管理" : "新建車間資料管理"} 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          afterClose={() => {
            set_s_PSData({});
            // call_getAllPS();
          }}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_PSData={s_PSData} />
        </CustomModal>
       ) : null}
      
    </>
  )
}
export default PSdata;