import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Table } from 'antd';

import { fontSize, MySubtitleH4 } from '../../styled/publicTag';
import { Input , Button, message } from 'antd';

import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getAllLine, deleteLine, getLine } from '../../services/api';

import ModalContainer from './ModalContainer'

import {
  IconTrash,
  IconPen
} from '../../components/Icon';

import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';

message.config({
  duration: 1.5
})

const Station = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);  // 是否開啟 dialog
  const [s_LineData, set_s_LineData] = useState({});
  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 建立新的工線資料
  const createStation = () => {
    set_s_showModal(true);
  }

  // 搜尋工線資料
  const onSearch = (value, event) => {
    
    const call_getAllLine = async (value) => {
      
      if(value === '') return;
      
      const res = await getLine(s_searchValue);
      
      if(res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }

    }
    call_getAllLine(s_searchValue);
  }

  //編輯工線資料
  const editWl = rowData => {
    set_s_showModal(true);
    set_s_LineData(rowData);
  }

  // 刪除工線資料
  const removeWl = rowData => {
    Modal.confirm({
      title: '工線刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的工線無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteLine(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllLine();
      },
      cancelText: '取消',
    });
  }

  // 取得所有工線資料
  const call_getAllLine = async () => {
    try {
      const res = s_searchValue === '' ? await getAllLine() : await getLine(s_searchValue);
      if(res.status === true) {
        console.log('全部工線資料 = ', res.data)
        set_s_tableData(res.data)
      }
      
    } catch (e) {
      console.log('---------- api getAllStaffPerm error ----------');
      console.log(e)
    }
  }
  // 取得所有工線資料
   useEffect(() => { 
    call_getAllLine();
    if(s_showModal === false) {
      set_s_LineData({})
    }
  }, [s_showModal]);

  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value)
    if(value === '') {
      try {
        const res = await getAllLine();
        if(res.status === true) {
          console.log(res.data)
          set_s_tableData(res.data)
        }
        
      } catch (e) {
        console.log('---------- api getAllStaffPerm error ----------');
        console.log(e)
      }
    }
  }

  const tableColumns = [
    {
      title: '工線ID',
      dataIndex: 'wlID',
      key: 'wlID',
      align: 'center',
      // width: 100
    },
    {
      title: '工線名稱',
      dataIndex: 'wlNM',
      key: 'wlNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${300}px`
    },
    {
      title: '工站',
      dataIndex: 'wsNM',
      key: 'wsNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    },
    {
      title: '品牌',
      dataIndex: 'brand',
      key: 'brand',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${300}px`
    },
    {
      title: '型號',
      dataIndex: 'model',
      key: 'modle',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${300}px`
    },
    {
      title: '車間',
      dataIndex: 'psNM',
      key: 'psNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <div style={{display: 'flex'}}>
            <IconPen className='icon' onClick={() => editWl(rowData)} />
            <IconTrash className='icon' onClick={() => removeWl(rowData)} />
          </div>
        </div>
      )
    },
  ]

  return (
    <>
      <Title 
        title='工線資料管理'
        placeholder='請輸入欲查詢工線資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createStation}
      />
      <CustomTable 
        scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {s_showModal ? ( 
        <CustomModal 
          width='60%' 
          title={Object.keys(s_LineData).length > 0 ? "修改工線資料管理" : "新建工線資料管理"} 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          afterClose={() => {
            //set_s_LineData({})
            //call_getAllLine()
          }}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_LineData={s_LineData} />
        </CustomModal>
      ) : null}
      
    </>
  )
}
export default Station;