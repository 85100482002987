import { combineReducers } from 'redux'
import loadingReducer from './loading'
import navBarReducer from './navBarState'
import navBarInlineReducer from './navBarInline'
import themeReducer from './theme'
import deptReducer from './dept'
import postReducer from './post'
import initDataReducer from './post'
import userStateReducer from './userState'
//註冊所有Reduser
const allReducers = combineReducers({
  loading: loadingReducer,
  navBar: navBarReducer,
  navBarInline: navBarInlineReducer,
  theme: themeReducer,
  dept: deptReducer,
  post: postReducer,
  initData: initDataReducer,
  userState: userStateReducer
})
export default allReducers