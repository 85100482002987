import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, Select, Radio, Divider, message } from 'antd';
import { addPS, modifyPS } from '../../../services/api';

message.config({
  duration: 1.5
})

const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_s_showModal, s_PSData}) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields()
  }, [form, s_PSData]);

  const onSubmit = async values => {

    const type = Object.keys(s_PSData).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      // 新增車間資料
      const res = await addPS(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };

    if(type === 'modify') {
      // 修改車間資料
      const res = await modifyPS(values.psID, values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };
    
    
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  return (
    <>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          psID: Object.keys(s_PSData).length > 0 ? s_PSData.psID : '',
          psNM: Object.keys(s_PSData).length > 0 ? s_PSData.psNM : '', 
          psNotes: Object.keys(s_PSData).length > 0 ? s_PSData.psNotes : '', 
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item 
              //labelCol={{offset:2}} 
              wrapperCol={{span:22}}
              label={<label style={{color: '#6C6C6C'}}>車間ID</label>}
              name="psID"
              rules={Object.keys(s_PSData).length === 0 ? [{ required: true, message: '請填寫車間ID!' }] : null}
            >
                <Input disabled={Object.keys(s_PSData).length === 0 ? false : true} name='psID' placeholder="請填寫車間ID" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:22, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>車間名稱</label>}
              name="psNM"
              rules={[{ required: true, message: '請填寫車間名稱!' }]}
            >
              <Input name='psNM' placeholder="請填寫車間名稱" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} >
            <Form.Item 
              // labelCol={{offset:2}} 
              wrapperCol={{span:24}}
              label={<label style={{color: '#6C6C6C'}}>車間註記</label>}
              // rules={[{ required: true, message: '請填寫車間註記!' }]}
              name="psNotes"
            >
              <Input.TextArea 
                rows={4} 
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};
export default ModalContainer