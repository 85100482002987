import { useState, useEffect } from "react";

import { Row, Col, Select } from "antd";

import { getAllSophd } from "../../../services/apis/wosAPI";

import { MySubtitleH3, MyH1 } from "../../../styled/publicTag";

export default function SopSelect({ thisWpc, getData }) {
  const { Option } = Select;

  const [thisSopSelect, setThisStopSelect] = useState({
    sopID: "",
    version: "",
  });

  const [SopOptions, setSopOptions] = useState([]);

  useEffect(() => {
    if (thisWpc.sopID !== "" && thisWpc.version !== "") {
      setThisStopSelect({
        sopID: thisWpc.sopID,
        version: thisWpc.version,
      });
    } else {
      setThisStopSelect({
        sopID: "",
        version: "",
      });
    }
    const call_getAllSophd = async () => {
      const res = await getAllSophd(thisWpc);
      if (res.status) {
        setSopOptions(
          res.data?.filter(x => x.isvalid).map((x) => (
            <Option key={x.sopID} value={x.sopID} data={x}>
              {x.sopID}－{x.version}
            </Option>
          ))
        );
      } else {
        setSopOptions([])
      }
    };
    call_getAllSophd();

  }, [thisWpc]);

  useEffect(() => {
    getData(thisSopSelect);
  }, [thisSopSelect]);

  return (
    <Row gutter={[24, 24]} style={{ marginTop: "15px", marginLeft: "15px" }}>
      <Col span={18}>
        <MySubtitleH3>SOP文編選擇</MySubtitleH3>
      </Col>
      <Col span={4}>
        <MySubtitleH3>版碼</MySubtitleH3>
      </Col>
      <Col span={18}>
        <Select
          style={{ width: "100%", textAlign: "center" }}
          size="large"
          placeholder="選擇SOP文編"
          value={thisSopSelect.sopID}
          onChange={(_, recode) => {
            setThisStopSelect({
              sopID: recode.data.sopID,
              version: recode.data.version,
            });
          }}
        >
          {SopOptions}
        </Select>
      </Col>
      <Col span={4}>
        <Select
          size="large"
          style={{ width: "100%", textAlign: "center" }}
          value={thisSopSelect.version}
          disabled
        />
      </Col>
    </Row>
  );
}
