import styled from 'styled-components';
import { fontSize,MyMainColorH1 } from '../../styled/publicTag.js';
import { Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'


const TitleStyle = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.Color3};
  font-size: ${fontSize.h3};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .flex-r {
    display: flex;
  }

  .Input {
    margin-right: 12px;
    width: 300px;
  }
  // 圓角設定
  .Input input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .Input .ant-input:focus {
    box-shadow: none
  }

  // 圓角設定
  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  // 圓角設定
  .ant-select-selector {
    border-radius: 5px!important;
  }

  // 圓角設定
  .Input button {
    border-top-right-radius: 5px!important;
    border-bottom-right-radius: 5px!important;
  }

  .ant-input-group-addon {
    background-color: ${({ theme }) => theme.Color3};
    color: #FFF;
    border: 2px solid ${({ theme }) => theme.Color3};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`
const Title = ({children, ...props}) => {

  const { title, placeholder, onSearch, handleChange, handleClick } = props;

  return (
    <TitleStyle>
      <MyMainColorH1>{title}</MyMainColorH1>
      {children ? (
        <>
          {children}
        </>
        
      ) : (
        <div className="flex-r">
          <Input.Search 
            className='Input' 
            size='large' 
            placeholder={placeholder} 
            onChange={e => handleChange(e.target.value)} 
            onSearch={onSearch} enterButton 
          />
          <Button 
            onClick={handleClick} 
            type="primary" 
            style={{borderRadius: '5px'}} 
            icon={<PlusOutlined />} 
            size='large'
          >
            新增
          </Button>
        </div>
      )}
    </TitleStyle>
  )
};

export default Title;