import { useState, useEffect } from "react";
import { Row, Col, Table, Button, Space, Modal, message, InputNumber } from "antd";
import styled from "styled-components";

import { getAllPcid } from "../../../services/apis/wosAPI";
import { printer } from "../../../services/apis/printer";

import { MySubtitleH4, MyThemeH1,MyH1 } from "../../../styled/publicTag";

import WpcCardPrint from "./PrintModal/WpcCardPrint";

import useStateRef from "../../../utility/useStateRef";

const CustomTable = styled(Table)`
  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    font-size: 23px;
    white-space: nowrap;
    font-weight: 600;
  }
  .ant-table-cell {
    font-size: 23px;
    font-weight: 600;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
    font-size: 23px;
  }

  // table pagination center
  .ant-pagination-total-text {
    display: flex;
    align-items: center;
  }

  /* 取消線條 */
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  // 只留間隔線條
  .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border-right: 1px solid #c4c4c4;
  }

  /* pagination 靠左 */
  .ant-pagination-total-text {
    flex: 1;
  }

  .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th:last-child,
  .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td:last-child {
    border-right: none;
  }

  .once {
    background-color: ${({ theme }) => theme.tableOnce};
  }
  .twoe {
    background-color: ${({ theme }) => theme.tableTwoe};
  }

  .clickRowStyle {
    background-color: ${({ theme }) => theme.tableOnClick};
  }
`;

export default function PrintWpcCardTable({ thisWpc }) {
  const [isCardPrintModal, setIsCardPrintModal] = useState(false);

  const tableCol = [
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
    },
    {
      title: "生產序號",
      dataIndex: "pcid",
      key: "pcid",
    },
  ];

  // 原始data
  const [tableData, setTableData] = useState([]);

  // 被選擇的row key
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  // 被選擇的rowData
  const [selectedRowData, setSelectedRowData] = useState([])

  const [bdm71, setbdm71] = useState([]);

  const [pageInfo, setPageInfo] = useState({
    page: 1, //第一頁
    pageSize: 20, //回傳筆數
    // server res
    totalItems: 0,
    currentPage: 1,
    pageItemNumber: 20,
    startItemNumber: 0,
    endItemNumber: 0,
  });


  const [barcodePrintNum, setBarcodePrintNum, barcodePrintNumRef] = useStateRef(10)

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
      setSelectedRowData(selectedRows)
    },
    selectedRowKeys: selectedRowKeys
  };
  
  useEffect(()=>{
    const getAllkeys = tableData.map(x=> x.pcid)
    setSelectedRowData(tableData)
    setSelectedRowKeys(getAllkeys)
  },[tableData])

  useEffect(() => {
    call_getAllPcid(pageInfo);
  }, []);

  const call_getAllPcid = async (thisPageInfo) => {
    const res = await getAllPcid({
      UUID: thisWpc.UUID,
      sopID: thisWpc.sopID,
      version: thisWpc.version,
      ...thisPageInfo,
    });
    if (res.status) {
      setTableData(
        res.data.PCIDs.PCIDs.map((x) => {
          return { ...x, key: x.pcid };
        })
      );
      setPageInfo(prev=>{
       return {...prev, ...res.data.PCIDs.pageInfo}
      });
      // 補滿到10 方便列印使用
      // res.data.bdm71.splice(0,1)
     
      let old71 = []
      for (let i = 1 ; i < 11; i++) {
        if (i < 9){
          old71.push({ pos: `ASSY-0${i}`, note: "" });
        }else {
          old71.push({ pos: "", note: "" });
        }
      }
      setbdm71(old71);
      message.success(res.msg);
    } else {
      message.error(res.msg);
    }
  };

  const callPrinterCheckModal = () =>{
    Modal.confirm({
      title: <MyH1>請輸入列印數量？</MyH1>,
      content: <InputNumber size="large" defaultValue={barcodePrintNum} onChange={(e)=>setBarcodePrintNum(e)}/>,
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "800px",
      onOk() {
        call_printer();
      },
    });
  }

  const call_printer = async () => {
    if (selectedRowData.length === 0) message.error("尚未選擇條碼")
    const codesArr = selectedRowData.map((x) => {return{"barcode":x.pcid,"quantity":barcodePrintNumRef.current}});
    const res = await printer({ codes: codesArr });
    if (res.status) {
      message.success(res.msg);
    } else {
      message.error(res.msg);
    }
  };


  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <CustomTable
          columns={tableCol}
          dataSource={tableData.map(x=>{
            return {...x}
          })}
          rowClassName={(record, index) =>
            index % 2 === 1 //隔行變色
              ? "once"
              : "twoe"
          }
          scroll={{ y: 500 }}
          rowSelection={rowSelection}
          pagination={{
            pageSizeOptions:["20","50","100","500"],
            showSizeChanger: true,
            total: pageInfo.totalItems,
            current: pageInfo.page,
            pageSize: pageInfo.pageSize,
            onChange: (current, size) => {
              call_getAllPcid({ ...pageInfo, page: current, pageSize:size })
              setPageInfo(prev=>{
                return {...prev,  page:current,pageSize:size}
              })
            },
            showTotal: (total, range) => (
              <MySubtitleH4>
                共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
              </MySubtitleH4>
            ),
          }}
        />
      </Col>
      <Col span={24}>
        <Space>
          <Button
            type="primary"
            size="large"
            onClick={() => setIsCardPrintModal(true)}
          >
            途程卡列印
          </Button>
          <Button type="primary" size="large" onClick={callPrinterCheckModal}>
            條碼列印
          </Button>
        </Space>
      </Col>

      <Modal
        title={<MyThemeH1>途程卡列印</MyThemeH1>}
        visible={isCardPrintModal}
        width="50%"
        onCancel={() => setIsCardPrintModal(false)}
        cancelText="取消"
        destroyOnClose={true}
        footer={null}
        centered
        forceRender
      >
        {isCardPrintModal && (
          <WpcCardPrint thisData={thisWpc} bdm71={bdm71} pcids={tableData} />
        )}
      </Modal>
    </Row>
  );
}
