//將時間格式轉換成${0}天${0}小時${0}分${0}秒

function timeGap(nowTime, afterTime) {
  if (nowTime === "" || afterTime === "") {
    return `${0}天${0}小時${0}分${0}秒`;
  }

  var now = new Date(nowTime);

  var after = new Date(afterTime);

  var total = (now - after) / 1000;

  var day = parseInt(total / (24 * 60 * 60));

  if (day > 365) {
    return `${0}天${0}小時${0}分${0}秒`;
  }

  var afterDay = total - day * 24 * 60 * 60;

  var hour = parseInt(afterDay / (60 * 60));

  var afterHour = total - day * 24 * 60 * 60 - hour * 60 * 60;

  var min = parseInt(afterHour / 60);

  var afterMin = parseInt(
    total - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60
  );

  return `${day}天${hour}小時${min}分${afterMin}秒`;
}

export default timeGap;
