import { instance, conn, url } from "../handlerAPI";

//wocwo,login,header,navbar api

//-----------API---------------------------------------------------------------
//call api 時都加上 try catch && async await
//以call_為開頭

export const getAllPcid = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/getAllPcid`, {
    params: _params,
  });
};

export const login = (data) => {
  return instance.post(`${conn}://${url}/api/woc/login`, data);
};

export const getWsWl = () => {
  return instance.get(`${conn}://${url}/api/woc/getWsWl`);
};

export const getAps = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/getAps`, { params: _params });
};

export const apsWoN = (data) => {
  return instance.post(`${conn}://${url}/api/woc/apsWoN`, data);
};

export const getPNs = () => {
  return instance.get(`${conn}://${url}/api/woc/getPNs`);
};

export const manualWoN = (data) => {
  return instance.post(`${conn}://${url}/api/woc/manualWoN`, data);
};

export const getAllWpc = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/getAllWpc`, {
    params: _params,
  });
};

export const chooseWpcMold = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/chooseWpcMold`, {
    params: _params,
  });
};

export const chooseWpcStaff = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/chooseWpcStaff`, {
    params: _params,
  });
};

export const upStaff = (data) => {
  return instance.post(`${conn}://${url}/api/woc/upStaff`, data);
};

export const getAllStaffs = () => {
  return instance.get(`${conn}://${url}/api/woc/getAllStaffs`);
};

export const prefix = (data) => {
  return instance.post(`${conn}://${url}/api/woc/prefix`, data);
};

export const getWpc = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/getWpc`, { params: _params });
};

export const getMoldInfo = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/getMoldInfo`, {
    params: _params,
  });
};

export const chooseWpcMoldInfo = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/chooseWpcMoldInfo`, {
    params: _params,
  });
};

export const wpc = (data) => {
  return instance.post(`${conn}://${url}/api/woc/wpc`, data);
};

export const prepare = (data) => {
  return instance.post(`${conn}://${url}/api/woc/prepare`, data);
};

export const open = (data) => {
  return instance.post(`${conn}://${url}/api/woc/open`, data);
};

export const pause = (data) => {
  return instance.post(`${conn}://${url}/api/woc/pause`, data);
};

export const resume = (data) => {
  return instance.post(`${conn}://${url}/api/woc/resume`, data);
};

export const close = (data) => {
  return instance.post(`${conn}://${url}/api/woc/close`, data);
};

export const downStaff = (data) => {
  return instance.post(`${conn}://${url}/api/woc/downStaff`, data);
};

export const suffix = (data) => {
  return instance.post(`${conn}://${url}/api/woc/suffix`, data);
};

export const downLine = (data) => {
  return instance.post(`${conn}://${url}/api/woc/downLine`, data);
};

export const initPage = () => {
  return instance.get(`${conn}://${url}/api/woc/initPage`);
};

export const getAllRtnWpc = () => {
  return instance.get(`${conn}://${url}/api/woc/getAllRtnWpc`);
};

export const returnWpcApi = (data) => {
  return instance.post(`${conn}://${url}/api/woc/returnWpc`, data);
};

export const timeline = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/timeline`, { params: _params });
};

export const getPauseList = () => {
  return instance.get(`${conn}://${url}/api/woc/getPauseList`);
};

export const getDirList = () => {
  return instance.get(`${conn}://${url}/api/woc/getDirList`);
};

export const getAbnList = () => {
  return instance.get(`${conn}://${url}/api/woc/getAbnList`);
};

export const report = (data) => {
  return instance.post(`${conn}://${url}/api/woc/report`, data);
};

export const getAllAbn = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/getAllAbn`, {
    params: _params,
  });
};

export const sip = () => {
  return instance.get(`${conn}://${url}/api/woc/sip`);
};

export const sop = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/sop`, { params: _params });
};

export const getAllMultiWpc = () => {
  return instance.get(`${conn}://${url}/api/woc/getAllmultiWpc`);
};

export const resolve = (data) => {
  return instance.post(`${conn}://${url}/api/woc/resolve`, data);
};

export const uploadMultiWpc = (data) => {
  return instance.post(`${conn}://${url}/api/woc/uploadMultiWpc`, data);
};

export const getMulti = (_params) => {
  return instance.get(`${conn}://${url}/api/woc/getMulti`, { params: _params });
};

export const logout = () => {
  return instance.post(`${conn}://${url}/api/woc/logout`);
};

// 當站生產狀況
export const wsSearch = () => {
  return instance.get(`${conn}://${url}/api/woc/sfc/wsSearch`);
};

export const getAllSophd = (_params) => {
  return instance.get(`${conn}://${url}/api/getAllSophd`, { params: _params });
};

export const generateBarcode = (data) => {
  return instance.post(`${conn}://${url}/api/woc/generateBarcode`, data);
};

export const deleteBarcode = (data) => {
  return instance.post(`${conn}://${url}/api/woc/deleteBarcode`, data);
};

export const deleteWon = (data) => {
  return instance.post(`${conn}://${url}/api/woc/delete`, data);
};
