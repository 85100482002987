import React from 'react';
// import SVGcomponent1 from './SVGcomponent1'
//import scada from './scada.svg'
//import { ReactComponent as MySvg } from './scada.svg'

const TestSVG = () => {
  return (
    <div>
      {/* <SVGcomponent test="Ariean" /> */}
      {/* <SVGcomponent1 test="Ariean" /> */}
    </div>
  )
}

export default TestSVG