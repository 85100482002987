import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import moment from 'moment'
import { freezeStaff } from '../../../services/api';
import { Form, Button, Input, Row, Col, Modal, DatePicker, message, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`

  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const FreezeContainer = ({ set_s_showFreezeModal, s_freezePersion, set_s_freezePersion }) => {
  const [form] = Form.useForm();

  const onSubmit = values => {
    Modal.confirm({
      title: '用戶凍結確認',
      icon: <ExclamationCircleOutlined style={{color: 'orange'}} />,
      content: '請先確認輸入的離職日期無誤，再進行凍結',
      okText: '確定',
      onOk: async () => {
        const tmpObj = {
          'staffID': values.staffID,
          'enterT': moment(set_s_freezePersion.enterT),
          'quitT': moment(values.quitT)
        }
        console.log('凍結資料 = ', tmpObj)
        const res = await freezeStaff(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);

        if(res.status === true) {
          // 清除要被凍結的人員資料
          set_s_freezePersion({});

          // 關閉對話框
          set_s_showFreezeModal(false)
        }

      },
      cancelText: '取消',
    });
  }

  const handleCancel = () => {
    set_s_showFreezeModal(false)
  }

  return (
    <>
      <StyledForm
        name="basic"
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          'peopleNM': s_freezePersion.peopleNM,
          'staffID': s_freezePersion.staffID,
          'quitTString': s_freezePersion.quitTString ? moment(s_freezePersion.quitTString) : null
        }}
      >
        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}} 
              label={<label style={{color: '#6C6C6C'}}>姓名</label>} 
              name="peopleNM" 
            >
              <Input disabled name='peopleNM' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}} 
              label={<label style={{color: '#6C6C6C'}}>工號</label>} 
              name="staffID" 
            >
              <Input disabled name='staffID' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              // className="customItem" 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>離職日期</label>} 
              name="quitTString" 
              rules={[{ required: true, message: '請選擇入職日期!' }]}
            >
              <DatePicker placeholder='請選擇離職日期' style={{width: '100%'}} />
            </Form.Item>
          </Col>
        </Row>
        
        <Divider />

        <Row>
          <Col offset={16} span={8}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{marginRight: '30px', borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};

export default FreezeContainer;
