/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { DataContext } from "../../index";

import { getAllProduct, getAllWsID, getLine } from '../../../../services/api';

import {
  addSophd,
  modifySophd,
  copySop,
  getAllSoptb
} from "../../../../services/apis/sopAPI";

import {
  Form,
  InputNumber,
  Button,
  Row,
  Col,
  Input,
  Select,
  Table,
  message,
  Radio,
} from "antd";

import { MenuOutlined } from "@ant-design/icons";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import './index.css';
const innerWidth = window.innerWidth;

const CustomForm = styled(Form)`
  .ant-input,
  .ant-picker,
  .ant-input-number {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px !important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto;
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    color: green
  }

  .row-dragging td {
    padding: 16px;
    color: pink
  }

  .row-dragging .drag-visible {
    visibility: visible;
    color: red
  }

`;
const StyleTable = styled(Table)`
  
  .border {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
  }
  
  /* 滾動條樣式 */
  ::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 7px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
    border-radius: 10px;
  }

  // 勾勾的顏色
  .successIcon {
    color: ${({ theme }) => theme.Color1};
  }

  //表格內容
  .ant-table-tbody .ant-table-cell {
    padding: 8px;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
    
  }

  .ant-table-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  //table 的圓角
  .ant-table-container,
  .ant-table {
    border-radius: 10px !important;
  }

  .ant-table-body {
    border-bottom: .5px solid rgb(217, 217, 217);
    border-left: .5px solid rgb(217, 217, 217);
    border-right: .5px solid rgb(217, 217, 217);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* width */
  .ant-table-body::-webkit-scrollbar {
    width: 10px !important;
  }

  // 讓滾動條下半部不要擋住table的圓角
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 10px;
  }

  .checkIcon {
    text-align: center;
    font-size: 20px;
  }

  /* .checkIcon > * {
    color: ${({ theme }) => theme.Color3};
  } */

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    padding: 8px;
    font-size: ${innerWidth <= 1440 ? '12px' : '16px'};
    white-space: nowrap;
  }

  .center {
    text-align: center;
  }

  .Icon {
    cursor: pointer;
  }

  .hidden {
    display: none
  }

  .fileLink {
    color: ${({ theme }) => theme.Color3};
    cursor: pointer;
  }

  .row-min-width {
    min-width: 100px;
  }
  .clickRowStyle {
    background-color: rgba(253, 255, 203, .5);
  }

  .row-dragging {
    background: red;
    border: 1px solid #ccc;
  }
  

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
  .ant-form-item {
    margin-bottom: 0px!important;
  }
`;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#CCC", fontSize: "25px" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const StandardModalContainer = ({
  s_editData,
  set_s_editData,
  set_s_showModal,
}) => {
  const [form] = Form.useForm();

  const [s_PN, set_s_PN] = useState([]); // 料號

  const { c_lightData } = useContext(DataContext);

  const [s_isCopy, set_s_isCopy] = useState(false); // 控制控點要顯示哪個下拉選單
  const [s_ctrlspot, set_s_ctrlspot] = useState(""); // 控制控點要顯示哪個下拉選單
  const [s_ws, set_s_ws] = useState([]); // 空點選 ws，空點編號的選單
  const [s_wl, set_s_wl] = useState([]); // 空點選 wl，空點編號的選單

  const [s_tableData, set_s_tableData] = useState([]);

  const tableCol = [
    {
      title: "排序",
      dataIndex: "sort",
      width: 30,
      classopnoame: "drag-visible",
      align: "center",
      render: () => Object.keys(s_editData).length === 0 && <DragHandle />,
    },
    { 
      title: "工位控程", 
      dataIndex: "sopno", 
      align: "center",
      onCell: (record) => {
        return {
          style: {
            color: '#0000FF'
          }
        }
      }
    },
    { title: "組裝序", dataIndex: "assyno", align: "center" },
    { title: "檢測序", dataIndex: "inspno", align: "center" },
    { title: "包裝序", dataIndex: "packno", align: "center" },
  ];

  // 拖曳結束時更新資料順序，並重新排序sopno
  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log('oldIndex = ', oldIndex)
    console.log('newIndex11 = ', newIndex)
    console.log('s_tableData = ', s_tableData)
    // return
    // 更改陣列順序
    function arrayMoveMutable(array, fromIndex, toIndex) {
      const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

      if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

        const [item] = array.splice(fromIndex, 1);
        array.splice(endIndex, 0, item);
      }
    }
    // 更改陣列順序
    function arrayMoveImmutable(array, fromIndex, toIndex) {
      array = [...array];
      arrayMoveMutable(array, fromIndex, toIndex);

      const tmpArray = array.map((item, index) => {
        return {
          ...item,
          wpcsopno: (index + 1).toString(),
          nextsopno: array.length === index + 1 ? "T" : (index + 2).toString(),
          wsID: item.wsID ? item.wsID : "WS01",
        };
      });

      return tmpArray;
    }

    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(s_tableData),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      // console.log("Sorted items: ", newData);
      const newData1 = newData.map((item, index) => {
        return {
          ...item,
          key: index.toString(),
          index: index,
          // sopno: index + 1,
          sopno: index+1
        };
      });
      console.log("newData1 = ", newData1);
      set_s_tableData(newData1);
    }
  };

  const DraggableContainer = (props) => {
    // console.log('props = ', props)
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };

  const DraggableBodyRow = ({ classopnoame, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = s_tableData.findIndex(
      (x) => x.key === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  // 第一次渲染取得料號、ws、wl 下拉選單
  useEffect(() => {
    const call_getProduct = async () => {
      try {
        const res = await getAllProduct();
        console.log("全部料號資料 = ", res);
        if (res.status === true) {
          set_s_PN(res.data);
        } else {
          set_s_PN([]);
        }
      } catch (e) {
        set_s_PN([]);
        console.log(
          "-------------------- call_getProduct error --------------------"
        );
      }
    };
    call_getProduct();

    const call_getAllWsID = async () => {
      try {
        const res = await getAllWsID();
        console.log("全部工站資料 = ", res);
        if (res.status === true) {
          set_s_ws(res.data);
        } else {
          set_s_ws([]);
        }
      } catch (e) {
        set_s_ws([]);
        console.log(
          "-------------------- call_getAllWsID error --------------------"
        );
      }
    };
    call_getAllWsID();

    const call_getLine = async () => {
      try {
        const res = await getLine("");
        console.log("全部工線資料 = ", res);
        if (res.status === true) {
          set_s_wl(res.data);
        } else {
          set_s_wl([]);
        }
      } catch (e) {
        set_s_wl([]);
        console.log(
          "-------------------- call_getLine error --------------------"
        );
      }
    };
    call_getLine();
  }, []);

  // 卸載時清除被編輯的資料
  useEffect(() => {
    return () => {
      set_s_editData({});
    };
  }, []);

  // 如果是編輯狀態要計算控點編號要顯示哪個下拉選單
  useEffect(() => {
    console.log("s_editData = ", s_editData);
    if (Object.keys(s_editData).length > 0) {
      set_s_ctrlspot(s_editData.ctrlspot);
      call_getAllSoptb()

      // let tableArr = [];

      // for (let i = 1; i <= s_editData.assyno; i++) {
      //   tableArr.push({ assyno: i, inspno: 0, packno: 0 });
      // }
      // for (let i = 1; i <= s_editData.inspno; i++) {
      //   tableArr.push({ assyno: 0, inspno: i, packno: 0 });
      // }
      // for (let i = 1; i <= s_editData.packno; i++) {
      //   tableArr.push({ assyno: 0, inspno: 0, packno: i });
      // }

      // tableArr = tableArr.map((x,i)=>{
      //   return {...x, key:i, sopno:i+1}
      // })


      // s_setTableData(tableArr);
    }
  }, [s_editData]);

  // 據此條目創建
  const createNew = () => {
    console.log(s_tableData);
    const template = { ...s_editData };

    // 先清空被編輯的資料
    set_s_editData({});

    // 塞資料
    form.setFieldsValue({
      PN: template.PN,
      pdtNM: template.pdtNM,
      isvalid: template.isvalid,
      assyno: template.assyno,
      inspno: template.inspno,
      packno: template.packno,
      sopno: template.sopno,
      note: null,
    });

    set_s_isCopy(true);
  };

  // 取得所有投料項目明細
  const call_getAllSoptb = async () => {
    try {
      // console.log(c_lightData)
      const res = await getAllSoptb({ sopID: c_lightData.sopID, version: c_lightData.version });
      console.log('res = ', res)
      if (res.data) {
        console.log('全部投料項目明細 = ', res.data);
        
        const dataWithKey = res.data.map((item, index) => {
          return {
            ...item,
            key: index.toString(),
            index: index,
          };
        });

        set_s_tableData(dataWithKey)
      }

    } catch (e) {
      console.log('---------- api getAllSoptb error ----------');
      console.log(e)
    }
  } 

  const onChange = (info) => {
    const { changedFields } = info;
    console.log(changedFields);
    // 改變料號要自動帶入品名
    if (changedFields[0].name[0] === "PN") {
      if (
        changedFields[0].value === "" ||
        changedFields[0].value === undefined
      ) {
        form.setFieldsValue({
          pdtNM: "",
        });
      } else {
        form.setFieldsValue({
          pdtNM:
            s_PN.length > 0 &&
            s_PN.find((item) => item.PN === changedFields[0].value) &&
            s_PN.find((item) => item.PN === changedFields[0].value).pdtNM,
        });
      }
    }

    // 計算總控程數
    if (
      changedFields[0].name[0] === "assyno" ||
      changedFields[0].name[0] === "inspno" ||
      changedFields[0].name[0] === "packno"
    ) {
      let assyno = form.getFieldValue("assyno");
      let inspno = form.getFieldValue("inspno");
      let packno = form.getFieldValue("packno");

      console.log("assyno = ", assyno);
      console.log("inspno = ", inspno);
      console.log("packno = ", packno);

      let tableArr = [];

      for (let i = 1; i <= assyno; i++) {
        const singleData = s_tableData.filter(item => item.assyno === i)

        if (singleData.length > 0){
          tableArr.push(singleData[0])
        }else{
          tableArr.push({ assyno: i, inspno: 0, packno: 0 });
        }
      }
      for (let i = 1; i <= inspno; i++) {
        const singleData = s_tableData.filter(item => item.inspno === i)

        if (singleData.length > 0){
          tableArr.push(singleData[0])
        }else{
          tableArr.push({ assyno: 0, inspno: i, packno: 0 });
        }
      }
      for (let i = 1; i <= packno; i++) {
        const singleData = s_tableData.filter(item => item.packno === i)

        if (singleData.length > 0){
          tableArr.push(singleData[0])
        }else{
          tableArr.push({ assyno: 0, inspno: 0, packno: i });
        }
      }

      tableArr = tableArr.map((x,i)=>{
        return {...x, key:i.toString(), sopno:i+1}
      })

      set_s_tableData(tableArr);

      if (assyno === null || inspno === null || packno === null) {
        form.setFieldsValue({
          sopno: "",
        });
      }

      if (assyno !== null && inspno !== null && packno !== null) {
        form.setFieldsValue({
          sopno: assyno + inspno + packno,
        });
      }
    }

    // 判斷控點編號要顯示 wl 還是 ws 的下拉選單
    if (changedFields[0].name[0] === "ctrlspot") {
      // 如果清空控點要重新選擇控點編號
      // if(changedFields[0].value === undefined) {
      form.setFieldsValue({
        spotID: undefined,
      });
      // }

      if (changedFields[0].value) {
        set_s_ctrlspot(changedFields[0].value);
      }
    }
  }

  // 點擊確定送出時執行
  const onSubmit = async (values) => {
    const type = s_isCopy ? "copy" : Object.keys(s_editData).length === 0 ? "create" : "modify";

    // 只在新增時使用
    const tmpValues = {
      hd: values,
      tb: s_tableData,
    };
    
    const modifyVal = { ...values };
    
    // 據此條目創建
    if (type === "copy") {
      console.log("要傳給後端的資料 = ", tmpValues);
      // return
      const res = await copySop(tmpValues);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    }

    // 建立
    if (type === "create") {
      console.log("要傳給後端的資料 = ", tmpValues);
      // return
      const res = await addSophd(tmpValues);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    }

    // 修改
    if (type === "modify") {
      const modifyValues = Object.assign(modifyVal, {
        sopID: s_editData.sopID,
        version: s_editData.version,
      });

      console.log("準備送給後端建立的資料 = ", modifyValues);

      const res = await modifySophd(modifyValues);
      if (res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    }
    
    set_s_showModal(false);
  };

  const handleCancel = () => {
    set_s_showModal(false);
  };

  return (
    <>
      <Form.Provider
        onFormChange={(formName, info) => {onChange(info)}}
      >
        <CustomForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            PN: Object.keys(s_editData).length > 0 ? s_editData.PN : null,
            pdtNM: Object.keys(s_editData).length > 0 ? s_editData.pdtNM : null,
            sopID: Object.keys(s_editData).length > 0 ? s_editData.sopID : null,
            version: Object.keys(s_editData).length > 0 ? s_editData.version : null,
            isvalid:
              Object.keys(s_editData).length > 0 ? s_editData.isvalid : true,
            soptype:
              Object.keys(s_editData).length > 0 ? s_editData.soptype : null,
            ctrlspot:
              Object.keys(s_editData).length > 0 ? s_editData.ctrlspot : null,
            spotID:
              Object.keys(s_editData).length > 0 ? s_editData.spotID : null,
            assyno:
              Object.keys(s_editData).length > 0 ? s_editData.assyno : null,
            inspno:
              Object.keys(s_editData).length > 0 ? s_editData.inspno : null,
            packno:
              Object.keys(s_editData).length > 0 ? s_editData.packno : null,
            sopno: Object.keys(s_editData).length > 0 ? s_editData.sopno : null,
            note: Object.keys(s_editData).length > 0 ? s_editData.note : null,
          }}
        >
          <Row gutter={[24, 12]}>
            <Col span={8}>
              <Form.Item
                label={
                  <label style={{ color: "rgba(0, 0, 0, 0.25)" }}>工位文編</label>
                }
                name="sopID"
              >
                <Input disabled />
              </Form.Item>

            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <label style={{ color: "rgba(0, 0, 0, 0.25)" }}>版碼</label>
                }
                name="version"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>是否有效</label>}
                name="isvalid"
                rules={[{ required: true, message: "請選擇是否有效!" }]}
              >
                <Radio.Group name="isvalid">
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 12]}>
            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>料號</label>}
                name="PN"
                rules={[{ required: true, message: "請選擇料號!" }]}
              >
                <Select
                  placeholder="選擇料號"
                  allowClear
                  showSearch
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                >
                  {s_PN.length > 0 &&
                    s_PN.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.PN}>
                          {item.PN}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <label style={{ color: "rgba(0, 0, 0, 0.25)" }}>品名</label>
                }
                name="pdtNM"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 12]}>
            <Col span={8}>
              <Form.Item
                label={
                  <label style={{ color: "rgba(0, 0, 0, 0.25)" }}>
                    總工位數
                  </label>
                }
                name="sopno"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 12]}>
            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>組裝序</label>}
                name="assyno"
                type="number"
                rules={[
                  { required: true, message: "請輸入組裝序!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value < 0) {
                        return Promise.reject(new Error("請輸入大於0的數字!"));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <InputNumber
                  name="assyno"
                  style={{ width: "100%" }}
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>檢測序</label>}
                name="inspno"
                rules={[
                  { required: true, message: "請輸入品檢序!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value < 0) {
                        return Promise.reject(new Error("請輸入大於0的數字!"));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                type="number"
              >
                <InputNumber
                  name="inspno"
                  style={{ width: "100%" }}
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>包裝序</label>}
                name="packno"
                rules={[
                  { required: true, message: "請輸入包裝序!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value < 0) {
                        return Promise.reject(new Error("請輸入大於0的數字!"));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                type="number"
              >
                <InputNumber
                  name="packno"
                  style={{ width: "100%" }}
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <StyleTable
                dataSource={s_tableData}
                columns={tableCol}
                pagination={false}
                rowKey="key"
                rowClassopnoame="editable-row"  
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                    // cell: EditableCell,
                  },
                }}
              />
            </Col>

            <Col span={24}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>備註</label>}
                name="note"
              >
                <Input.TextArea name="note" rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={18}>
              {Object.keys(s_editData).length > 0 ? (
                <Button
                  size="large"
                  style={{ marginRight: "20px", borderRadius: "5px" }}
                  onClick={createNew}
                >
                  據此條目創建
                </Button>
              ) : null}
            </Col>
            <Col span={6}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="large"
                  style={{ marginRight: "20px", borderRadius: "5px" }}
                  onClick={handleCancel}
                >
                  取消
                </Button>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius: "5px" }}
                  >
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </CustomForm>
      </Form.Provider>
    </>
  );
};

export default StandardModalContainer;
