import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Select, message, Row, Col } from "antd";
import Cookies from "universal-cookie";

import logo from "../../assets/images/logo.png"; // relative path to image
import loginSvg from "../../assets/images/login.svg";
import { ReactComponent as LogoSvg } from "../../assets/images/logo1.svg";
//import './index.css'
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login } from "../../services/api";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${loginSvg});
  background-size: cover;
  //background-repeat: no-repeat;
  background-position: center 110px;
  background-position: left;
  background-repeat: no-repeat;
  background-color: rgb(40, 98, 139);
  position: relative;

  .logo {
    width: 100%;
  }

  .logoSvg {
    position: absolute;
    top: 60px;
    left: 80px;
    width: 10%;
    height: 140px;
    z-index: 10;
  }

  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;
const Box = styled.div`
  position: absolute;
  top: 130px;
  left: 70px;
  width: 650px;
  height: 70%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1440px) {
    width: 550px;
  }
`;
const Title = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 12px;
  font-family: Microsoft JhengHei;
  text-align: center;
`;
const CustomSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 5px !important;
  }
`;
const StyledForm = styled(Form)`
  // 客製化輸入框寬度設定100
  .w-100 {
    width: 100%;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
  }

  .ant-input {
    font-size: 20px;
  }
  .ant-input-affix-wrapper {
    height: 50px;
  }

  .ant-input-suffix {
    font-size: 20px;
    color: #bebebe;
  }

  .ant-input::placeholder {
    color: #bebebe;
    font-size: 20px;
  }

  .ant-select-selector {
    border-radius: 5px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }
`;

function Login() {
  const cookies = new Cookies();

  const [form] = Form.useForm();

  let history = useHistory();

  const [buttonLodaing, setButtonLoading] = useState(false);

  const onFinish = async (values) => {
    setButtonLoading(true);
    console.log(values);
    const res = await login(values);
    setButtonLoading(false);
    if (res.status) {
      // dispatch(setInitData(res.data))
      history.push("/dashboard");
    } else {
      console.log("values = ", values);
      console.log("res = ", res);
      message.error(res.msg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //form.resetFields();
  };

  return (
    <Wrapper>
      {/* <LogoSvg className='logoSvg' /> */}
      <img
        src={logo}
        alt=" 信景生產戰情系統"
        style={{
          position: "absolute",
          top: "60px",
          left: "80px",
          width: "10%",
          height: "140px",
          zIndex: "10",
        }}
      />
      <Box>
        <div>
          <Title>帳戶登錄 - 生產戰情系統</Title>
          <StyledForm
            onFinish={onFinish}
            name="basic"
            form={form}
            autoComplete="off"
            layout="vertical"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="account" // account
                  rules={[
                    { required: true, message: "請輸入正確的用戶名或郵件" },
                  ]}
                >
                  <Input
                    placeholder="請輸入帳號"
                    prefix={
                      <UserOutlined
                        style={{
                          fontSize: "20px",
                          color: "#BEBEBE",
                          marginRight: "20px",
                        }}
                      />
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="staffPwd"
                  rules={[{ required: true, message: "請輸入正確的密碼" }]}
                >
                  <Input.Password
                    placeholder="請輸入密碼"
                    prefix={
                      <LockOutlined
                        style={{
                          fontSize: "20px",
                          color: "#BEBEBE",
                          marginRight: "20px",
                        }}
                      />
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginTop: "50px" }}>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{
                      width: "100%",
                      height: "60px",
                      backgroundColor: "#A3D3E8",
                      border: "1px solid #A3D3E8",
                      borderRadius: "5px",
                      fontSize: "20px",
                    }}
                    loading={buttonLodaing}
                  >
                    登入
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </StyledForm>
        </div>
      </Box>
    </Wrapper>
  );
}
export default Login;

/*
return (
    <div className="login">
      <div className="login-right-bubble">
        <div className="login-box">
          <img alt="logo" className="login-logo" src={logo} />
          <div className="login-main">
            <h2>帳戶登入</h2>
            <Form
              form={form}
              className="login-form"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="large"
            >
              <Form.Item
                name="account" // account
                rules={[{ required: true, message: '請輸入正確的用戶名或郵件' }]}
              >
                <Input
                  placeholder="請輸入帳號"
                  prefix={<EditTwoTone />}
                />
              </Form.Item>
              <Form.Item
                name="staffPwd"
                rules={[{ required: true, message: '請輸入正確的密碼' }]}
              >
                <Input.Password
                  size="large"
                  placeholder="請輸入密碼"
                  prefix={<LockTwoTone />}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: '100%' }}
                  loading={buttonLodaing}
                >
                  登入
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
*/
