import { useRef, useEffect } from "react";

import JsBarcode from "jsbarcode";
import { useReactToPrint } from "react-to-print";
import { Row, Col, Button } from "antd";

import "./index.css"

export default function BarcordPrint() {
  const printRef = useRef("");
  const printRefOne = useRef("");
  const printRefTwo= useRef("");
  const cont = useRef(0);

  function convertCanvasToImage(canvas) {
    console.log(canvas)
    var image = new Image();
    image.src = canvas.toDataURL("image/png");
    return image;
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    // content: () => convertCanvasToImage(printRef.current),
    bodyClass: "print-style",
    pageStyle: `
    body{
      counter-reset: page-number;  
    }
    @page {
      // size:25mm 14mm;
      size:2in 1in;
    }
    .Wraper{
      page-break-inside: avoid;
      position: relative;
    }
  `,
  });

  
  useEffect(() => {
    let canvas = document.createElement("canvas")
    JsBarcode(canvas, "VNL521800002", {
      // textMargin: 0,
      margin: 5,
      // marginBottom: 0,
      fontSize:50,
      width:4,
      height:100,
    });
    printRefOne.current.appendChild(convertCanvasToImage(canvas));
  }, [printRefOne]);

  useEffect(() => {
    let canvas = document.createElement("canvas")
    JsBarcode(canvas, "VNL521800002", {
      marginTop: 5,
      // marginBottom: 0,
      // textMargin: 0,
      fontSize:50,
      // width:0.65,
      // height:10,
      width:4,
      height:100,
    });
    printRefTwo.current.appendChild(convertCanvasToImage(canvas));
  }, [printRefTwo]);

  return (
    <Row style={{height:"400px"}}>
      <Col className="print" span={24}>
        <div ref={printRef} className="flex-c barcord-print Wraper">
          <div ref={printRefOne} className="barcord-img"></div>
          <div ref={printRefTwo} className="barcord-img"></div>
        </div>
      </Col>
      <Col  span={24}>
        <Button onClick={handlePrint}>列印</Button>
      </Col>
    </Row>
  );
}
