import React from "react";
//import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
//import { CSSTransition, TransitionGroup } from "react-transition-group";
import Users from "../pages/Users";
import SystemLogin from "../pages/SystemLogin";
import DashBoard from "../pages/DashBoard";
import BasicProductInfo from "../pages/BasicProductInfo";
import OutBarcodeSearch from "../pages/OutBarcodeSearch";
import PSdata from "../pages/PSdata";
import Station from "../pages/Station";
import Line from "../pages/Line";
import Department from "../pages/Department";
import JobTitle from "../pages/JobTitle";
import TestSVG from "../pages/TestSVG";
import WPC from "../pages/WPC";
import ProductBarCode from "../pages/ProductBarCode";
import ProductSearch from "../pages/ProductSearch";

// SFC
import SFC from "../pages/SFC";

const Routes = () => {
  let location = useLocation();

  const r_userState = useSelector((allReducers) => allReducers.userState);
  console.log(r_userState);
  return (
    <>
      {Object.keys(r_userState).length > 0 && (
        <Switch location={location}>
          {r_userState.setAPS === "1" && (
            <Route path="/sfc" children={<SFC />} />
          )}

          {r_userState.setAPP === "1" && (
            <Route path="/dashboard" children={<DashBoard />} />
          )}

          {r_userState.setDBS === "1" && (
            <Route path="/ProductBarCode" children={<ProductBarCode />} />
          )}
          {r_userState.setDBS === "1" && (
            <Route path="/ProductSearch" children={<ProductSearch />} />
          )}
          {r_userState.setDBS === "1" && (
            <Route path="/OutBarcodeSearch" children={<OutBarcodeSearch />} />
          )}

          {r_userState.setBDM === "1" && (
            <Route path="/BasicProductInfo" children={<BasicProductInfo />} />
          )}
          {r_userState.setBDM === "1" && (
            <Route path="/wpc" children={<WPC />} />
          )}

          {/* <Route path="/WorkStations" children={WorkStations} /> */}

          {r_userState.pdsID === "superman" ? (
            <Route path="/PSdata" children={<PSdata />} />
          ) : null}
          {r_userState.pdsID === "superman" ? (
            <Route path="/Station" children={<Station />} />
          ) : null}
          {r_userState.pdsID === "superman" ? (
            <Route path="/Line" children={<Line />} />
          ) : null}

          {r_userState.setSDM === "1" ? (
            <Route path="/Users" children={<Users />} />
          ) : null}

          {r_userState.setSDM === "1" ? (
            <Route path="/SystemLogin" children={<SystemLogin />} />
          ) : null}

          {r_userState.pdsID === "superman" ? (
            <Route path="/Department" children={<Department />} />
          ) : null}

          {r_userState.pdsID === "superman" ? (
            <Route path="/JobTitle" children={<JobTitle />} />
          ) : null}

          <Route path="/TestSVG" children={TestSVG} />

          {/* <Route path="/LineDetails" children={LineDetails} /> */}
          <Redirect exact from="*" to="/" />
        </Switch>
      )}
    </>
  );
};
export default Routes;
