//當前收起狀態
const theme = localStorage.getItem('theme')
const bool = (themeString) => {
  if (themeString === 'false') {
    return false
  } else if(themeString === 'true') {
    return true
  } else {
    return true
  }
}
const themeReducer = (state = bool(theme), action) => {
  switch (action.type) {
    case 'CHANGE_THEME': {
      return action.payload
    }
    default:
      return state
  }
}
export default themeReducer