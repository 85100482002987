import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MySubtitleH4, fontSize } from '../../../styled/publicTag';
import { getWpc, addProduct, modifyProduct, getAllStation } from '../../../services/api'

import { Form, Button, Row, Col, Input, Select, Table, Divider, message, Radio, Collapse } from 'antd';

import { PlusCircleFilled, MinusCircleFilled, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const CustomForm = styled(Form)`
  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
`

const CustomCollapse = styled(Collapse)`
  .ant-collapse-header {
    color: #7ab1f4!important;
    font-size: 15px;
  }
`

const ModalContainer = ({s_productData, set_s_showModal}) => {
  
  const [form] = Form.useForm();

  const [s_isShowStandard, set_s_isShowStandard] = useState(false);

  // const [s_isBOMrequire, set_s_isBOMrequire] = useState(false);

  // const [s_isnetwtrequire, set_s_isnetwtrequire] = useState(false);

  const [s_Station, set_s_Station] = useState([]);

  // wpcSN: 途程號  nextSN: 次途程號  wsID: 站別
  const [s_wpc, set_s_wpc] = useState([
    {'wpcSN': '1', 'nextSN': 'T', 'wsID': 'WS01'},
  ]);
  const handleCancel = () => {
    set_s_showModal(false)
  }
  // 選擇站別
  const handleChange = (value, keyIndex) => {
    const tmpArray = s_wpc.map((item, index) => {
      if(keyIndex === index) {
        return {...item, 'wsID': value}
      } else {
        return item
      }
    })
    set_s_wpc(tmpArray)
  }
  // 增加、刪減途程
  const toggle = type => {
    let tmpArray = [...s_wpc];
    if(type === 'increase') {
      tmpArray.push({'wpcSN': 1, 'nextSN': 1});
      const newState = tmpArray.map((item, index) => {
        return {
          'wpcSN': index+1, 
          'nextSN': tmpArray.length === index+1 ? 'T' : index+2, 
          'wsID': item.wsID ? item.wsID : 'WS01'
        }
      })
      set_s_wpc(newState);
    }

    if(type === 'decrease') {
      if(tmpArray.length === 1) return;
      tmpArray.pop();
      const newState = tmpArray.map((item, index) => {
        return {
          'wpcSN': index+1, 
          'nextSN': tmpArray.length === index+1 ? 'T' : index+2, 
          'wsID': item.wsID ? item.wsID : 'WS01'
        }
      })
      set_s_wpc(newState)
    }
  }

  // 取得『標準途程資料』、工站資料, 根據初始資料判斷要不要顯示『標準途程資料』
  useEffect(() => {
    //console.log('s_productData = ', s_productData)
    const call_getProduct = async () => {
      if(Object.keys(s_productData).length === 0) return;
      const res = await getWpc(s_productData.PN)
      console.log(res)
      if(res.status) {
        console.log('getWpc = ', res.data)
        set_s_wpc(res.data)
      }
    }
    call_getProduct();

    // 取得工站資料
    const call_getAllStation = async () => {
      const res = await getAllStation();
      if(res.status === true) {
        set_s_Station(res.data)
        console.log(res.data)
      }
    }
    call_getAllStation();

    if(s_productData && (s_productData.pdtClass === '2' || s_productData.pdtClass === '3')) {
      set_s_isShowStandard(true);
    };
  }, []);

  // 增加、刪減途程後滾動條要出現在最下方
  useEffect(() => {
    let dom = document.querySelector('.wpcWrapper');
    if(dom) {
      dom.scrollTop = (s_wpc.length)*70;
    };
  }, [s_wpc]);
  
  useEffect(() => {
    form.resetFields();

  }, [form]);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_productData).length > 0 ? 'edit' : 'create';
    // 轉字串，不然後端接不到
    const tmpWpc = Object.entries(s_wpc).map(([key, value]) => {
      return {
        'wpcSN': value.wpcSN.toString(),
        'nextSN': value.nextSN.toString(),
        'wsID': value.wsID.toString()
      }
    })

    let product = JSON.parse(JSON.stringify(values));
    product = {
      ...product,
      netwt: parseInt(product.netwt),
      pbom: parseInt(product.pbom)
    }
   
    
    if(type === 'create') {
      let tmpObj = {
        'product': product,
        'wpc': tmpWpc
      }
      console.log('送給後端的資料 = ', tmpObj)
      const res = await addProduct(tmpObj);
      res.status === true ? message.success(res.msg) : message.error(res.msg)
      set_s_showModal(false)
    }
    
    if(type === 'edit') {
      console.log('送給後端的資料 = ', product)
      const res = await modifyProduct(product.PN, product);
      console.log('??? = ', product)
      res.status === true ? message.success(res.msg) : message.error(res.msg)
      set_s_showModal(false)
    }
  }

  const tableColumns = [
    { title: '途程號', dataIndex: 'first', key: 'first', width: '30%' },
    { title: '次途程號', dataIndex: 'sec', key: 'sec', width: '30%' },
    { 
      title: '站別', 
      dataIndex: 'step', 
      key: 'step', 
      width: '30%',
      render: (text, rowData, index) => {
        if(Object.keys(s_productData).length === 0 ) { // 只有新建資料要可以選
          return(
            <Select defaultValue={rowData.step} onChange={(value) => handleChange(value, rowData)} style={{ width: 150 }}>
              <Select.Option value={0}>ws01</Select.Option>
              <Select.Option value={1}>ws02</Select.Option>
              <Select.Option value={2}>ws03</Select.Option>
              <Select.Option value={3}>ws04</Select.Option>
              <Select.Option value={4}>ws05</Select.Option>
            </Select>
          )
        }else {
          return 'ws01'
        }
        
      }
    },
    {
      title: '動作',
      dataIndex: 'work',
      key: 'work',
      width: '10%',
      render: (text, rowData, index) => {
        // 只有新建可以選圖層
        if(Object.keys(s_productData).length === 0 && s_wpc.length === index+1) {
          //alert('123')
          return (
            <div className="checkIcon">
              <PlusCircleFilled onClick={() => toggle('increase')} className='icon' />
              <MinusCircleFilled onClick={() => toggle('decrease')} className='icon' />
              {/* <PlusCircleFilled onClick={() => increase(rowData)} className='icon' />
              <MinusCircleFilled onClick={() => decrease(rowData)} className='icon' /> */}
            </div>
          )
        } else {
          return (
            <div className="checkIcon"></div>
          )
        }
        // if(s_list.length === index+1) {
        //   return (
        //     <div className="checkIcon">
        //       <PlusCircleFilled onClick={() => toggle('increase')} className='icon' />
        //       <MinusCircleFilled onClick={() => toggle('decrease')} className='icon' />
        //       {/* <PlusCircleFilled onClick={() => increase(rowData)} className='icon' />
        //       <MinusCircleFilled onClick={() => decrease(rowData)} className='icon' /> */}
        //     </div>
        //   )
        // } else {
        //   return null
        // }
        
      }
    },
  ]
  const callback = () => {
    console.log('callback')
  }

  return (
    <>
      <Form.Provider
        // 選擇半成品才出現標準途程資料
        onFormChange={(formName, info) => {
          console.log(info)
          const { changedFields } = info;
          if(changedFields[0].name[0] === 'pdtClass') {
            if(changedFields[0].value === '2' || changedFields[0].value === '3') {
              set_s_isShowStandard(true)
            } else { // 若先選半成品，但是後悔改選其他的話要 reset s_wpc
              set_s_isShowStandard(false);
              set_s_wpc([
                {'wpcSN': '1', 'nextSN': 'T', 'wsID': 'WS01'}
              ])
            }
          }

          if(changedFields[0].name[0] === 'netwt') {
            console.log('changedFields[0].value = ', changedFields[0].value)
          }
        }}
      >
        <CustomForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            PN: s_productData ? s_productData.PN : '',  // 料號
            pdtNM: s_productData ? s_productData.pdtNM : '',  // 品名
            pdtClass: s_productData ? s_productData.pdtClass : null, // 料階
            unit: s_productData ? s_productData.unit : null,   //計量單位
            punit: s_productData ? s_productData.punit : null,   //包裝單位
            pbom: s_productData.pbom ? s_productData.pbom : 1,
            mtypeNM: s_productData ? s_productData.mtypeNM : null,
            setStock: s_productData ? s_productData.setStock : null,  // 是否設庫管項目
            netwt: s_productData ? s_productData.netwt : null,  // 標準單重
            setpid: s_productData ? s_productData.setpid : null,  // 最小計量條碼
            setidm: s_productData ? s_productData.setidm : null,  // 是否序碼管理
            matl: s_productData ? s_productData.matl : null,  // 材料
          }}
        >
          <CustomCollapse defaultActiveKey={['1', '2']} onChange={callback}>
          
            <Panel header="產品基本資料" key="1">
              <Row className="ml30">
                <Col span={8} className="mb12">
                  <Form.Item 
                    labelCol={{span:6}}
                    wrapperCol={{span:20}} 
                    label={<label style={{color: '#6C6C6C'}}>料號</label>}
                    name="PN"
                    rules={[{ required: true, message: '請輸入料號!' }]}
                  >
                    <Input 
                      name='PN' 
                      disabled={Object.keys(s_productData).length > 0 ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="mb12">
                  <Form.Item 
                    labelCol={{span:6}}
                    wrapperCol={{span:20}} 
                    label={<label style={{color: '#6C6C6C'}}>品名</label>}
                    name="pdtNM"
                    rules={[{ required: true, message: '請輸入品名!' }]}
                  >
                    <Input name='bbb' />
                  </Form.Item>
                </Col>
                <Col span={8} className="mb12">
                  <Form.Item 
                    labelCol={{span:6}}
                    wrapperCol={{span:20}} 
                    label={<label style={{color: '#6C6C6C'}}>機種</label>}
                    name="mtypeNM"
                    //rules={[{ required: true, message: '請輸入機種!' }]}
                  >
                    <Input name='mtypeNM' />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="ml30">
                <Col span={8}>
                  <Form.Item 
                    labelCol={{span:6}}
                    wrapperCol={{span:20}} 
                    label={<label style={{color: '#6C6C6C'}}>料階</label>}
                    name="pdtClass"
                    rules={[{ required: true, message: '請選擇料階!' }]}
                  >
                    <Select placeholder="選擇料階" allowClear>
                      <Select.Option value="0">原料/耗材</Select.Option>
                      <Select.Option value="1">零件(件)</Select.Option>
                      <Select.Option value="2">半成品</Select.Option>
                      <Select.Option value="3">成品</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item 
                    labelCol={{span:6}}
                    wrapperCol={{span:20}} 
                    label={<label style={{color: '#6C6C6C'}}>計量單位</label>}
                    rules={[{ required: true, message: '請選擇計量單位!' }]}
                    name="unit"
                  >
                    <Select placeholder="選擇計量單位" allowClear>
                      <Select.Option value="0">件</Select.Option>
                      <Select.Option value="1">公克(重量)</Select.Option>
                      <Select.Option value="2">公斤(重量)</Select.Option>
                      <Select.Option value="3">公分(長度)</Select.Option>
                      <Select.Option value="4">公尺(長度)</Select.Option>
                      <Select.Option value="5">公升(容積)</Select.Option>
                      <Select.Option value="6">加侖(容積)</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item 
                    // labelCol={{span:6}}
                    wrapperCol={{span:20}} 
                    labelAlign='left'
                    className="mb-0"
                    label={<label style={{color: '#6C6C6C'}}>是否件碼管理</label>}
                    name="setidm"
                  >
                    <Radio.Group name="setidm">
                      <Radio value="Y">是</Radio>
                      <Radio value="N">否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                
              </Row>
             
            </Panel>
            
            {s_isShowStandard ? (
              <Panel header="標準途程資料" key="2">
                <div className='wpcWrapper'>
                  {s_wpc.map((item, index) => {
                    return (
                      <Row key={index} className="ml30">
                        <Col span={8} className="mb12">
                          <Form.Item 
                            labelCol={{span:6}}
                            wrapperCol={{span:20}} 
                            label={<label style={{color: '#6C6C6C'}}>途程號</label>}
                          >
                            <Input value={item.wpcSN} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={8} className="mb12">
                          <Form.Item 
                            labelCol={{span:6}}
                            wrapperCol={{span:20}} 
                            label={<label style={{color: '#6C6C6C'}}>次途程號</label>}
                            // name="PN"
                          >
                            <Input value={item.nextSN} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
      
                          <Row>
                            <Col span={12}>
                              <Form.Item 
                                label={<label style={{color: '#6C6C6C'}}>站別</label>}
                              >
                                <Select
                                  onChange={(value, options) => handleChange(value, index)} 
                                  disabled={Object.keys(s_productData).length > 0 ? true : false}
                                  value={item.wsID} 
                                  // style={{ width: Object.keys(s_productData).length === 0 ? '70%' : '100%' }}
                                >
                                  {s_Station && s_Station.map((item, index) => {
                                    return <Select.Option key={index} value={item.wsID}>{item.wsNM}</Select.Option>
                                  })}
                                  {/* <Select.Option value='WS01'>WS01</Select.Option>
                                  <Select.Option value='WS02'>WS02</Select.Option>
                                  <Select.Option value='WS03'>WS03</Select.Option>
                                  <Select.Option value='WS04'>WS04</Select.Option>
                                  <Select.Option value='WS05'>WS05</Select.Option> */}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              {/* 新建產品基本資料且是最後一筆 */}
                              {Object.keys(s_productData).length === 0 && s_wpc.length === (index+1) ? (
                                <div style={{marginTop: '30px'}}>
                                  <PlusCircleOutlined onClick={() => toggle('increase')} style={{fontSize: '20px', marginLeft: '20px'}} />
                                  <MinusCircleOutlined onClick={() => toggle('decrease')} style={{fontSize: '20px', marginLeft: '5px'}} />
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                          
                        </Col>
                      </Row>
                    )
                  })}
                </div>
              </Panel>
            ) : null}
            

          </CustomCollapse>
          <Divider style={{margin: '0px 0px 12px 0px'}} />

            <Divider style={{marginBottom: '6px'}} />

            <Row className="ml30">
              <Col span={8}>
                <Row style={{display: 'none'}}>
                  {/* {Object.keys(s_staffData).length > 0 ? ( */}
                  <Col offset={2}>
                    <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={() => alert('施工中')}>
                      據此創建新條目
                    </Button>
                  </Col>
                  {/* ) : null} */}
                </Row>
              </Col>
              <Col offset={8} span={8}>
                <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                  <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                    取消
                  </Button>
                  <Form.Item>
                    <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                      確定
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
        </CustomForm>
      </Form.Provider>
    </>
  )

}

export default ModalContainer;

/*
pagination={{
  defaultCurrent: 1,
  total: pageInfo.totalItems,
  // current: abcBoardConfig.page,
  showSizeChanger: true,
  pageSize: pageInfo.pageSize,
  pageSizeOptions: ["5", "10"],
  onChange: (current, size) => {
    setPageInfo({
      ...pageInfo,
      pageSize: size
    })
    setAbcBoardConfig({
      ...abcBoardConfig,
      page: current,
      pageSize: size,
    });
  },
  showTotal: (total, range) => (
    <MySubtitleH4>
      共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
    </MySubtitleH4>
  ),
}}
*/