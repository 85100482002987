import { ReactComponent as IconChart } from '../../assets/icon/icon-chart.svg'
import { ReactComponent as ClockIcon } from '../../assets/icon/clock.svg'
import { ReactComponent as TimeIcon} from '../../assets/icon/time.svg'
import { ReactComponent as BoxCheck} from '../../assets/icon/box-check.svg'
import { ReactComponent as BoxFailed} from '../../assets/icon/box-failed.svg'
import { ReactComponent as ClockDesk} from '../../assets/icon/clock-desk.svg'
import { ReactComponent as HourGlass} from '../../assets/icon/hourglass.svg'
import { ReactComponent as StopWatch} from '../../assets/icon/stopwatch.svg'
import { ReactComponent as PDF} from '../../assets/icon/pdf.svg'
import { ReactComponent as Print} from '../../assets/icon/print.svg'
import { ReactComponent as Theme} from '../../assets/icon/theme.svg'
import { ReactComponent as LightIcon } from '../../assets/icon/light.svg'
import { ReactComponent as DarkIcon } from '../../assets/icon/dark.svg'
import { ReactComponent as ArrowIcon} from '../../assets/icon/arrows.svg'
import { ReactComponent as Woskb} from '../../assets/icon/woskb.svg'
import { ReactComponent as Wosmc} from '../../assets/icon/wosmc.svg'
import { ReactComponent as Wosqc} from '../../assets/icon/wosqc.svg'
import { ReactComponent as Wosrepowork} from '../../assets/icon/wosrepowork.svg'
import { ReactComponent as Wossip} from '../../assets/icon/wossip.svg'
import { ReactComponent as Wostk} from '../../assets/icon/wostk.svg'
import { ReactComponent as Woswo} from '../../assets/icon/woswo.svg'
import { ReactComponent as Wosck} from '../../assets/icon/wosck.svg'
import { ReactComponent as IconWon} from '../../assets/icon/icon-won.svg'
import { ReactComponent as IconHdc} from '../../assets/icon/icon-hdc.svg'
import { ReactComponent as IconMhc} from '../../assets/icon/icon-mhc.svg'
import { ReactComponent as IconOkc} from '../../assets/icon/icon-okc.svg'
import { ReactComponent as IconOpenT} from '../../assets/icon/icon-openT.svg'
import { ReactComponent as IconPlanHead} from '../../assets/icon/icon-planHead.svg'
import { ReactComponent as IconPlanQty} from '../../assets/icon/icon-planQty.svg'
import { ReactComponent as IconPn} from '../../assets/icon/icon-pn.svg'
import { ReactComponent as IconWs} from '../../assets/icon/icon-ws.svg'
import { ReactComponent as IconWl} from '../../assets/icon/icon-wl.svg'
import { ReactComponent as IconUser} from '../../assets/icon/icon-user.svg'
import { ReactComponent as IconLock} from '../../assets/icon/icon-lock.svg'
import { ReactComponent as IconStandbyTime} from '../../assets/icon/icon-standbyTime.svg'
import { ReactComponent as IconProccessTime} from '../../assets/icon/icon-proccessTime.svg'
import { ReactComponent as IconSun} from '../../assets/icon/icon-sun.svg'
import { ReactComponent as IconMoon} from '../../assets/icon/icon-moon.svg'
import { ReactComponent as IconPdtNM} from '../../assets/icon/icon-pdtnm.svg'
import { ReactComponent as IconNgC} from '../../assets/icon/icon-ngc.svg'
import { ReactComponent as IconAct} from '../../assets/icon/icon-act.svg'
import { ReactComponent as IconPpe} from '../../assets/icon/icon-ppe.svg'
import { ReactComponent as IconTar} from '../../assets/icon/icon-tar.svg'
import { ReactComponent as IconQty} from '../../assets/icon/icon-qty.svg'
import { ReactComponent as IconOverView} from '../../assets/icon/icon-lines-overview.svg'
import { ReactComponent as IconPress } from '../../assets/icon/icon-press.svg'

const myIcon = {
	IconQty,
	IconTar,
	IconPpe,
	IconAct,
	IconChart,
	ClockIcon,
	TimeIcon,
	BoxCheck,
	BoxFailed,
	ClockDesk,
	HourGlass,
	StopWatch,
	PDF,
	Print,
	Theme,
	LightIcon,
	DarkIcon,
	ArrowIcon,
	Woswo,
	Wostk,
	Woskb,
	Wosmc,
	Wosqc,
	Wosck,
	Wosrepowork,
	Wossip,
	IconWon,
	IconHdc,
	IconMhc,
	IconOkc,
	IconOpenT,
	IconPlanHead,
	IconPlanQty,
	IconPn,
	IconWs,
	IconWl,
	IconUser,
	IconLock,
	IconStandbyTime,
	IconProccessTime,
	IconSun,
	IconMoon,
	IconPdtNM,
	IconNgC,
	IconOverView,
	IconPress
}

export default myIcon