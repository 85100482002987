import styled from 'styled-components';
import { fontSize, MySubtitleH4 } from '../../styled/publicTag.js';
import { Modal } from 'antd';

const StyleModal = styled(Modal)`
  // Modal title 下方的分隔線
  .ant-modal-header {
    border-bottom: 2px solid #F0F0F0;
  };

  .ant-modal-body {
    padding-bottom: 0px;
  };
  // Modal title
  .ant-modal-title {
    font-size: ${fontSize.h4};
    font-weight: 500
  };
  // 關閉 icon 的字體大小
  .ant-modal-close-x {
    font-size: ${fontSize.h4};
  };
`
const CustomModal = ({children, ...props}) => {
  //const {children} = props
  return (
    <StyleModal {...props}>
      {children}
    </StyleModal>
  )
}
export default CustomModal;

