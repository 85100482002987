import styled from 'styled-components';

const WrapperStyle = styled.div`
  padding: 0px 20px 0px 25px;
  background-color: ${({ theme }) => theme.mainBg};
`

const Wrapper = ({children}) => {

  return (
    <WrapperStyle>
      {children}
    </WrapperStyle>
  )
};

export default Wrapper;