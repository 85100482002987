import { instance, conn, url } from "../handlerAPI";

export const getAllWpc = (_params) => {
  return instance.get(`${conn}://${url}/api/printer/getAllWpc`, {
    params: _params,
  });
};

export const getAllWpcGo = (_params) => {
  return instance.get(`${conn}://${url}/api/printer/getAllWpcGo`, {
    params: _params,
  });
};

export const getOneStaff = (_params) => {
  return instance.get(`${conn}://${url}/api/printer/getOneStaff`, {
    params: _params,
  });
};

export const printer = (data) => {
  return instance.post(`http://localhost:9487/api/printer`, data);
};
