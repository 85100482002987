import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  InputNumber,
  Space,
  Button,
  message,
  Modal,
} from "antd";
import moment from "moment";
import styled from "styled-components";
import { MySubtitleH3, MyH1 } from "../../../styled/publicTag";
import { deleteBarcode, generateBarcode } from "../../../services/apis/wosAPI";

export default function TagSetting({ thisWpc }) {
  // 周次
  const [week, setWeek] = useState();

  const [pcidStr, setPcidStr] = useState("");

  const [ppidStr, setPpidStr] = useState("");

  const [sn, setSN] = useState(1);

  useEffect(() => {
    setWeek(moment().week());
  }, []);

  useEffect(()=>{
    setPcidStr("")
    setPpidStr("")
  },[thisWpc])

  const call_generateBarcode = async () => {
    const res = await generateBarcode({
      woN: thisWpc.woN,
      UUID: thisWpc.UUID,
      planqty: thisWpc.planQty,
      week: week,
      pcidstr: pcidStr,
      ppidstr: ppidStr,
      SN: sn,
    });

    if (res.status) {
      message.success(res.msg);
    } else {
      message.error(res.msg);
    }
  };

  const call_deleteBarcode = async () => {
    const res = await deleteBarcode({
      woN: thisWpc.woN,
      UUID: thisWpc.UUID,
      week: week,
      pcidstr: pcidStr,
      ppidstr: ppidStr,
      SN: sn,
    });

    if (res.status) {
      message.success(res.msg);
    } else {
      message.error(res.msg);
    }
  };

  const CheckModal = (call_API, title, content) => {
    if (Object.keys(thisWpc).length === 0) {
      message.error("請先選擇工單")
      return
    }
    if (!pcidStr || !ppidStr || sn <= 0){
      message.error("請填寫完整資料")
      return
    }
    Modal.confirm({
      title: <MyH1>{title}</MyH1>,
      content: <MySubtitleH3>{content}</MySubtitleH3>,
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "500px",
      onOk() {
        call_API();
      },
    });
  };

  return (
    <Row
      gutter={[12, 12]}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Col span={24}>
        <Space>
          <MySubtitleH3>生產序號</MySubtitleH3>
          <Input value={pcidStr} onChange={(e) => setPcidStr(e.target.value)} />
          <InputNumber
            size="large"
            value={sn}
            min={1}
            style={{ width: "100%" }}
            onChange={(e) => setSN(e)}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Space>
          <MySubtitleH3>出貨序號</MySubtitleH3>
          <Input value={ppidStr} onChange={(e) => setPpidStr(e.target.value)} />
          <InputNumber
            size="large"
            value={sn}
            min={0}
            style={{ width: "100%" }}
            onChange={(e) => setSN(e)}
          />
        </Space>
      </Col>
      <Col span={24} style={{display:"flex",justifyContent:"flex-end"}}>
        <Space>
          <Button
            size="large"
            type="primary"
            danger
            onClick={() =>
              CheckModal(
                call_deleteBarcode,
                "是否刪除序號？",
                "請確認序號是否正確"
              )
            }
          >
            刪除
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() =>
              CheckModal(
                call_generateBarcode,
                "是否產生序號？",
                "請確認序號是否正確"
              )
            }
          >
            產生
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
