export const themeLight = {
  //mainColor: "#58beb3", //主色
  mainColor: "#71AFF8", //主色
  cardBg: "#F9F9F9",   // 生產看板bg
  Color1: "#71AFF8", //副色1
  Color2: "#70CEF6", //副色2
  Color3: "#5e86c1",
  Color4: '#c4dcec',  //#b9ddf5
  Color5: '#5B6D91',
  wlFlag: "#4278DF", //生產看板旗標顏色
  error: "#d86363", //錯誤
  warning: "#EBB549", //警告
  success: "#699f5b", //成功
  pageBg: "#ffffff", //整體背景
  componentsBg: "#ffffff", // 區塊背景
  retext: "rgba(255,255,255,0.8)", //有背景時文字顏色
  text: "rgba(0,0,0,0.85)", //文字顏色
  text1: '#6C6C6C',
  text2: "rgba(0,0,0,0.85)", //文字顏色
  text3: "#FFF",
  borderColor: "#f0f0f0",
  shadowColor: "#8D8D8D",
  disabledColor: "rgb(219, 217, 217)",
  disabledTextColor: "#c5c5c5",
  dashBox: '#FFF',
  tableBodyBorder: 'rgb(217, 217, 217)',
  inputBorderColor: '#d9d9d9',
  modalBodyBg: '#FFF',
  SysteamName: '#315ae7',
  HeaderTag: '#398eef',
  HeaderTime: '#398eef',
  menuIcon: 'inherit',
  dashBoardCard: '#71AFF8',
};

export const themeDark = {
  mainColor: "#C0DCF0",
  Color1: "#C0DCF0",
  Color2: "#8bd1c8",
  Color3: "#F9F9F9", 
  Color4: '#2b587b',
  Color5: '#b1c0e0',
  cardBg: "#292929",   // 生產看板bg
  header: "#000",
  wlFlag: "#4278DF", //生產看板旗標顏色
  modalColor: "#5995BC",
  pageBg: "rgba(255,255,255,0.8)",
  componentsBg: "#fafafa",
  retext: "rgba(255,255,255,0.8)",
  text: "#FFF",
  text1: '#FFF',
  text2: "rgba(0,0,0,0.85)", //文字顏色
  text3: "#000",
  headerColor: "#272727",
  mainBg: "#000",
  dashBox: 'rgb(58, 71, 78)',
  disabledColor: "#a9a9a9",
  tableBodyBorder: '#000',
  inputBorderColor: '#5995BC',
  modalBodyBg: '#424242',
  SysteamName: '#FFF',
  HeaderTag: '#FFF',
  HeaderTime: 'yellow',
  menuIcon: 'yellow',
  dashBoardCard: 'rgb(60, 103, 188)'
};
