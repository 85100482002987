import React, { useState, useEffect, useRef, useContext } from 'react';
import { message, Input, Spin } from 'antd';

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';

import { CloseCircleOutlined, CheckCircleFilled, ExclamationCircleOutlined, PrinterOutlined } from '@ant-design/icons';

import { MySubtitleH4 } from '../../../styled/publicTag.js';
import { IconExcel } from '../../../components/Icon/index';

import OperateArea from '../../../components/Custom/OperateArea';
import Workbook from 'react-excel-workbook';

import { DataContext } from '../index';

import { getBarcodeWoNHd, getWoNAllPcidDetail } from "../../../services/api";

const innerWidth = window.innerWidth;

const HD = () => {

  const [scrollY, setScrollY] = useState(300)

  const [ is_spinning, set_is_spinning ] = useState(false);

  const [ s_tableData, set_s_tableData ] = useState([]);

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const { c_lightData, set_c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  const [ s_Excel, set_s_Excel ] = useState([]);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 搜尋
  const onSearch = (value, event) => {

    // const call_getBarcodeWoNHd = async (value) => {

    //   if (value === '') return;

    //   const res = await getBarcodeWoNHd({ woN: value });

    //   if (res.status) {
    //     set_s_tableData(res.data);
    //     message.success(res.msg);
    //   } else {
    //     set_s_tableData([]);
    //     message.error(res.msg);
    //   }

    //   set_c_lightData({})

    // }
    call_getBarcodeWoNHd(value);
    set_c_lightData({})
  }

  // 刪除搜尋文字時重新抓資料
  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value)
    if (value === '') {
      try {
        const res = await getBarcodeWoNHd();
        console.log('res = ', res)
        if (res.data) {
          const tmpData = res.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          });
          set_s_tableData(tmpData)
        }

      } catch (e) {
        console.log('---------- api getBarcodeWoNHd error ----------');
        console.log(e)
      }
    }
  }

  // 取得所有表頭資料
  const call_getBarcodeWoNHd = async () => {
    try {
      const res = s_searchValue === '' ? await getBarcodeWoNHd() : await getBarcodeWoNHd({ woN: s_searchValue });
      
      if (res.status) {
        console.log(res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        message.success(res.msg)
        set_s_tableData(tmpData)
      } else {
        message.error(res.msg)
        set_s_tableData([])
      }

    } catch (e) {
      console.log('---------- api getBarcodeWoNHd error ----------');
      console.log(e)
    }
  };

  useEffect(() => {
    call_getBarcodeWoNHd()
  }, []);

  useEffect(() => {
    
    if(Object.keys(c_lightData).length === 0) return;

    const call_getWoNAllPcidDetail = async () => {
      set_is_spinning(true)
      const res = await getWoNAllPcidDetail(c_lightData);
      console.log('excel res = ', res)
      if(res.status === true) {
        message.success(res.msg);
        set_s_Excel(res.data)
      }
      else {
        message.error(res.msg)
        set_s_Excel([]);
      }
      set_is_spinning(false)
    }

    call_getWoNAllPcidDetail()

  }, [c_lightData])

  const tableColumns = [
    {
      title: "工單號",
      dataIndex: "woN",
      align: "center",
      width: innerWidth <= 1440 ? `${200}px` : `${220}px`
    },
    {
      title: "產品型號",
      dataIndex: "dim1",
      align: "center",
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
    },
    {
      title: "生產序號起",
      dataIndex: "pcidstart",
      align: "center",
      width: innerWidth <= 1440 ? `${120}px` : `${200}px`,
    },
    {
      title: "生產序號末",
      dataIndex: "pcidend",
      align: "center",
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`,
    },
    {
      title: "預計產量",
      dataIndex: "planqty",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "生產數量",
      dataIndex: "okC",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "生產開始",
      dataIndex: "startTstr",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    },
    {
      title: "生產結束",
      dataIndex: "closeTstr",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    },
    // {
    //   title: '操作',
    //   dataIndex: 'render',
    //   key: 'render',
    //   width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
    //   align: 'center',
    //   render: (text, rowData, index) => (
    //     <div style={{display: 'flex', alignItems: 'center' }}>
    //       <PrinterOutlined style={{fontSize: '24px', marginRight: '5px'}} onClick={() => print(rowData)} />
    //       <OperateArea 
    //         onEditClick={() => editSOPHd(rowData)}
    //         onDeleteClick={() => removeSophd(rowData)}
    //       />
    //     </div>
    //   )
    // }
  ]

  const ExcelColumns = [
    {
      title: '生產序號',
      key: 'pcid'
    },
    {
      title: '掃碼內容',
      key: 'itemcode'
    },
    {
      title: '項目名稱',
      key: 'itemNM'
    },
    {
      title: '掃碼項目',
      key: 'itemanchor'
    },
    {
      title: '掃碼時間',
      key: 'updtTstr'
    },
    
  ];

  return (
    <Spin size="large" spinning={is_spinning}>
      <Title title='工單序號查詢'>
        <div className="flex-r">
          <Input.Search 
            className='Input' 
            size='large' 
            placeholder='請輸入生產序號' 
            onChange={e => handleChange(e.target.value)} 
            onSearch={onSearch} enterButton 
          />
          {Object.keys(c_lightData).length > 0 && (
            <span style={{height: '38px', cursor: 'pointer'}}>
              <Workbook 
                filename={`工單號_${c_lightData.woN}.xlsx`} 
                element={
                  <IconExcel 
                    style={{width: '30px', height: '38px', marginLeft: '10px', marginRight: '5px'}} 
                  />
              }>
                <Workbook.Sheet data={s_Excel} name="Sheet A">
                  {ExcelColumns.map((item, index) => {
                    return(
                      <Workbook.Column key={index} label={item.title} value={item.key} />
                    )
                  })}
                </Workbook.Sheet>
              </Workbook>
            </span>
          )}
        </div>
      </Title>

      <CustomTable 
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ x: 'max-content', y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        rowClassName={(record, index) => {
          const rowKey = record.pcidstart + record.pcidend;
          const dataKey = c_lightData.pcidstart + c_lightData.pcidend;
          const clickRowStyle = rowKey === dataKey ? "clickRowStyle" : ""
          return index % 2 === 1 ? `light ${clickRowStyle}` : `dark ${clickRowStyle}`
        }}
        
        onRow={(record) => {
          return {
            onClick: () => {
              const rowKey = record.pcidstart + record.pcidend;
              const dataKey = c_lightData.pcidstart + c_lightData.pcidend;
              if (rowKey === dataKey) {
                set_c_lightData({})
              } else {
                set_c_lightData(record)
              }
            }
          }
        }}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
    </Spin>
  )
};

export default HD;