import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { changeMenuStatus, changeNavBarInlineStatus } from "../../redux/action";
//import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useTheme } from "styled-components";

import Cookies from "universal-cookie";

import { NavLink, useLocation } from "react-router-dom";
import { Layout, Menu, Switch, Tooltip, Modal } from "antd";
import { HeaderTag, MyH3, fontSize } from "../../styled/publicTag";

import { logout, getStaff } from "../../services/api";
import ModalContainer from "../../pages/Users/ModalContainer";
import { ReactComponent as ProductWpc } from "../../assets/icon/icon-product-wpc.svg";
import { ReactComponent as BasicProduct } from "../../assets/icon/icon-basic-product-info.svg";

import {
  IconDashboard,
  IconSystemData,
  IconUsers,
  IconSystemLogin,
  IconBasicData,
  IconBasicProduct,
  IconProductWpc,
  IconPsData,
  IconStation,
  IconLine,
} from "../../components/Icon";

import {
  ExportOutlined,
  AlignLeftOutlined,
  UserOutlined,
  RightOutlined,
} from "@ant-design/icons";

import { IconJobTitle } from "../../components/Icon/newicon";

import Logo from "../../assets/images/logo.png";

const CustomMenu = styled(Menu)`
  overflow-x: hidden;
  .disabledIcon .ant-menu-title-content {
    color: rgba(0, 0, 0, 0.25);
  }
  .disabledIcon .anticon-export {
    color: rgba(0, 0, 0, 0.25);
  }
  .disabledIcon .ant-menu-submenu-arrow {
    color: rgba(0, 0, 0, 0.25);
  }
  .disabledIcon:hover {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-menu-item {
    margin: 0px !important;
  }
  .ant-menu-item .ant-menu-title-content {
    margin-left: 0px;
  }

  .ant-menu-submenux {
    height: 30px !important;
  }
  li > .ant-menu-submenu-title {
    margin: 0px;
  }
  .ant-menu-submenu-title,
  .ant-menu-title {
    height: 40px !important;
  }
  .ant-menu-sub li {
    height: 30px !important;
  }
`;
const CustomModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: 2px solid #f0f0f0;
  }
  .ant-modal-body {
    padding-bottom: 0px;
  }
  .ant-modal-title {
    font-size: ${fontSize.h4};
    font-weight: 500;
  }
  .ant-modal-close-x {
    font-size: ${fontSize.h4};
  }
`;

const CustomSider = styled(Layout.Sider)`
  .ant-layout-sider-children div > ul {
    //overflow-y: auto;
  }
  .ant-layout-sider-children div ul {
    background-color: ${({ theme }) => theme.header}!important;
  }
  .logoBox {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    //borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    background-color: ${({ theme }) => theme.header}!important;
  }
  .menuStyle {
    flex-grow: 1;
    border-right: 1px solid rgba(0, 0, 0, 0);
    font-size: 18px;
  }
`;

function NavBar({ user }) {
  const { Sider } = Layout;

  const { SubMenu } = Menu;

  const r_userState = useSelector((allReducers) => allReducers.userState);

  // click 個資管理時可以編輯自己的資料
  const [s_staffData, set_s_staffData] = useState({});
  const [s_showModal, set_s_showModal] = useState(false);
  const [s_isLock] = useState(true);

  const Dispatch = useDispatch();

  //get path name
  let location = useLocation();

  //const theme = useTheme()

  const Logout = async () => {
    const res = await logout();
  };

  // const r_authPage = useSelector(allReducers => allReducers.auth)
  const r_isNavbar = useSelector((allReducers) => allReducers.navBar);
  //const r_isNavBar = useSelector(allReducers => allReducers.navBar)
  const r_isNavbarInline = useSelector(
    (allReducers) => allReducers.navBarInline
  );
  //console.log('r_isNavbarInline = ', r_isNavbarInline)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMenuToggle = useCallback(() => {
    if (r_isNavbar === false) {
      Dispatch(changeMenuStatus(r_isNavbar));
    }
  });

  useEffect(() => {
    // 在 document 上绑定click事件，隐藏弹出層
    // console.log('menu打開')
    window.addEventListener("click", handleMenuToggle);
    return () => {
      window.removeEventListener("click", handleMenuToggle);
    };
  }, [handleMenuToggle, r_isNavbar]);

  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  const is_sider_state = (e) => {
    stop_propagation(e);
  };

  const is_change_navbar_inline = () => {
    Dispatch(changeNavBarInlineStatus(r_isNavbarInline));
  };
  const logoBox = {
    width: "100%",
    height: "64px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    //backgroundColor: '#F3F5F7'
  };
  const logoStyle = {
    height: "100%",
    width: "100%",
    // padding: "15px 30px",
    objectFit: "contain",
  };

  const siderStyle = {
    height: "100%",
    minHeight: "100%",
    //當navbar展開時覆蓋在header上面
    zIndex: r_isNavbar ? 1 : 3,
    boxShadow: "5px 0px 3px 2px rgba(0,0,0, 0.1)",
    //overflow: "auto",
    position: "fixed",
    // marginTop: r_isNavbar && r_isNavbarInline ? null : "64px",
    marginTop: function () {
      if (r_isNavbar) {
        return r_isNavbarInline ? null : "64px";
      }
    },
    // eslint-disable-next-line no-dupe-keys
    height: "100vh",
    left: 0,
  };

  const siderMainStyle = {
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
  };

  const menuStyle = {
    flexGrow: "1",
    borderRight: "1px solid rgba(0,0,0,0)",
    fontSize: "18px",
  };

  const menuBottomStyle = {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "flex-end",
    borderRight: "1px solid rgba(0,0,0,0)",
  };

  const style = {
    width: "28px",
    marginRight: "10px",
  };

  const menuItemStyle = {
    width: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const SubMenuItem = {
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  // click 個資管理時可編輯自己的資料
  const editUser = async () => {
    const res = await getStaff(r_userState.pdsID);
    // console.log('getStaff = ', res.data[0])
    if (res && res.status === true) {
      set_s_staffData(res.data[0]); // 塞要編輯的資料
      set_s_showModal(true); // 控制顯示 Modal 的開關
    }
  };
  const is_navbar = (e) => {
    // 使用 react 的 e.stopPropagation 不能阻止冒泡，需要使用 e.nativeEvent.stopImmediatePropagation，这里我们对其进行封装，方便多次调用
    //stop_propagation(e)
    Dispatch(changeMenuStatus(r_isNavbar));
  };
  //console.log('r_isNavbar = ', r_isNavbar)
  const colseNavbar = () => {
    Dispatch(changeMenuStatus(false));
  };
  return (
    <>
      <CustomSider
        trigger={null}
        collapsed={r_isNavbar}
        collapsedWidth={r_isNavbarInline ? 0 : 80}
        onClick={is_sider_state}
        width={230}
        // onCollapse={(collapsed, type) => {
        //   alert(collapsed)
        // }}
        style={siderStyle}
      >
        <div style={siderMainStyle}>
          <div className="logoBox">
            {/*style={logoBox} */}
            <img src={Logo} alt="鴻嘉駿生產戰情系統" style={logoStyle} />
          </div>
          <CustomMenu
            mode="inline"
            className="menuStyle"
            defaultSelectedKeys={location.pathname.replace("/", "")}
            style={{ position: "relative" }}
            // style={menuStyle}
          >
            {r_userState && r_userState.setAPS === "1" && (
              <Menu.Item
                style={{ height: "40px" }}
                key="sfc"
                icon={<IconDashboard style={style} />}
                onClick={colseNavbar}
              >
                <NavLink to="/sfc">生產控制管理</NavLink>
              </Menu.Item>
            )}
            {r_userState && r_userState.setAPP === "1" && (
              <Menu.Item
                style={{ height: "40px" }}
                key="dashboard"
                icon={<IconDashboard style={style} />}
                onClick={colseNavbar}
              >
                <NavLink to="/dashboard">數位生產看板</NavLink>
              </Menu.Item>
            )}

            {r_userState && r_userState.setDBS === "1" && (
              <>
                <Menu.Item
                  style={{ height: "40px" }}
                  key="ProductBarCode"
                  icon={<IconDashboard style={style} />}
                  onClick={colseNavbar}
                >
                  <NavLink to="/ProductBarCode">生產條碼查詢</NavLink>
                </Menu.Item>

                <Menu.Item
                  style={{ height: "40px" }}
                  key="OutBarcodeSearch"
                  icon={<IconDashboard style={style} />}
                  onClick={colseNavbar}
                >
                  <NavLink to="/OutBarcodeSearch">出貨條碼查詢</NavLink>
                </Menu.Item>

                <Menu.Item
                  style={{ height: "40px" }}
                  key="ProductSearch"
                  icon={<IconDashboard style={style} />}
                  onClick={colseNavbar}
                >
                  <Tooltip placement="right" title="工單資訊 返修紀錄">
                    <NavLink to="/ProductSearch">工單序號查詢</NavLink>
                  </Tooltip>
                </Menu.Item>
              </>
            )}

            {/* <Menu.Item style={{height: '40px'}} key="dashboard" icon={<IconDashboard style={style} />} onClick={colseNavbar}>
              <NavLink to="/dashboard">數位生產看板</NavLink>
            </Menu.Item> */}
            {/* <Menu.Item style={{height: '40px'}} key="dashboardCard" icon={<IconCard style={style} />} onClick={colseNavbar}>
              <NavLink to="/dashboardCard">數位生產圖框</NavLink>
            </Menu.Item> */}
            {/* <Menu.Item style={{height: '40px'}} key="abcboard" icon={<IconAbcboard style={style}/>} onClick={colseNavbar}>
              <NavLink to="/abcboard">異常管理看板</NavLink>
            </Menu.Item> */}
            {/* <Menu.Item style={{height: '40px'}} key="OverView" icon={<IconOverview style={style} />}>
              <NavLink to="/OverView">綜合效能總表</NavLink>
            </Menu.Item> */}
            {/* <Menu.Item style={{height: '40px'}} key="OverViewLines" icon={<IconLinesOverview style={style} />} onClick={colseNavbar}>
              <NavLink to="/OverViewLines">設備稼動總表</NavLink>
            </Menu.Item> */}
            {/* <Menu.Item style={{height: '40px'}} key="ProductionInfo" icon={<IconLineDetails style={style} />} onClick={colseNavbar}>
              <NavLink to="/ProductionInfo">設備生產狀況</NavLink>
            </Menu.Item> */}

            {/* {r_userState && (r_userState.setAPS === '1' || r_userState.pdsID === 'superman') ? (
               <Menu.Item style={{height: '40px'}} key="ApsManagement" icon={<IconApsManager style={style} />} onClick={colseNavbar}>
                <NavLink to="/ApsManagement">生管派工管理</NavLink>
              </Menu.Item>
            ) : null} */}

            {/* <SubMenu key="sub1" title="生產資訊查詢" icon={<IconInformation style={style} />} style={{ flexGrow: "1"}} > {/* title="生產資訊查詢" */}

            {/* <Menu.Item style={SubMenuItem} key="/WorkInProduction" icon={<IconInproduction style={menuItemStyle}/>} onClick={colseNavbar}>
                <NavLink to="/WorkInProduction">工單在製查詢</NavLink>
              </Menu.Item> */}

            {/* <Menu.Item style={SubMenuItem} key="/WorkWaybill" icon={<IconStation style={menuItemStyle}/>} onClick={colseNavbar}>
                <NavLink to="/WorkWaybill">工站途單查詢</NavLink>
              </Menu.Item> */}

            {/* <Menu.Item style={SubMenuItem} key="/WorkPassStations" disabled icon={<IconWsgo style={menuItemStyle}/>}>
                <NavLink to="/WorkPassStations">工站過站查詢</NavLink>
              </Menu.Item> */}

            {/* <Menu.Item style={SubMenuItem} key="/WorkResume" icon={<IconWsResume style={menuItemStyle}/>} onClick={colseNavbar}>
                <NavLink to="/WorkResume">工單履歷查詢</NavLink>
              </Menu.Item> */}

            {/* <Menu.Item style={SubMenuItem} key="/WorkStations" icon={<IconWsReport style={menuItemStyle}/>} onClick={colseNavbar}>
                <NavLink to="/WorkStations">工線日報查詢</NavLink>
              </Menu.Item> */}

            {/* </SubMenu>  */}
            <Menu.Divider
              style={{ height: "1px", backgroundColor: "rgba(0, 0, 0, 0.06)" }}
            />
            {/* 基本資料維護 */}
            {r_userState && r_userState.setBDM === "1" ? (
              <>
                <SubMenu
                  key="sub2"
                  title="基本資料維護"
                  icon={<IconBasicData style={style} />}
                  style={{ flexGrow: "1" }}
                >
                  <Menu.Item
                    style={SubMenuItem}
                    key="/BasicProductInfo"
                    icon={<IconBasicProduct style={menuItemStyle} />}
                    onClick={colseNavbar}
                  >
                    <NavLink to="/BasicProductInfo">產品基本資料</NavLink>
                  </Menu.Item>

                  {/* <Menu.Item style={SubMenuItem} key="/ProductWpc" icon={<IconProductWpc style={menuItemStyle}/>} onClick={colseNavbar}>
                    <NavLink to="/ProductWpc">產品途程資料</NavLink>
                  </Menu.Item> */}
                  {/* <Menu.Item key="/ProductWpc" icon={<ProductWpc width={20} height={20} />}>
                    <NavLink to="/ProductWpc">產品途程資料</NavLink>
                  </Menu.Item> */}
                  {/* <Menu.Item key="/ProductWpc" icon={<CoffeeOutlined />}>
                    <NavLink to="/ProductWpc">產品途程資料</NavLink>
                  </Menu.Item> */}

                  <Menu.Item
                    style={SubMenuItem}
                    key="/wpc"
                    icon={<IconProductWpc style={menuItemStyle} />}
                    onClick={colseNavbar}
                  >
                    <NavLink to="/wpc">工位標準管理</NavLink>
                  </Menu.Item>

                  {/* <Menu.Item style={SubMenuItem} key="/OemInfo" icon={<IconBasicProduct style={menuItemStyle}/>} onClick={colseNavbar}>
                    <NavLink to="/OemInfo">委外基本資料</NavLink>
                  </Menu.Item> */}

                  {r_userState && r_userState.pdsID === "superman" ? (
                    <></>
                  ) : null}

                  {/* <Menu.Item style={SubMenuItem} key="/Mould" icon={<IconMould style={menuItemStyle}/>} onClick={colseNavbar}>
                    <NavLink to="/Mould">生產模具管理</NavLink>
                  </Menu.Item> */}
                </SubMenu>
              </>
            ) : null}
            {/* 系統資料維護 */}
            {r_userState && r_userState.setSDM === "1" ? (
              <>
                <SubMenu
                  key="sub3"
                  title="系統資料維護"
                  icon={<IconSystemData style={style} />}
                  style={{ flexGrow: "1" }}
                >
                  <Menu.Item
                    style={SubMenuItem}
                    key="/Users"
                    icon={<IconUsers style={menuItemStyle} />}
                    onClick={colseNavbar}
                  >
                    <NavLink to="/Users">員工基本資料</NavLink>
                  </Menu.Item>

                  <Menu.Item
                    style={SubMenuItem}
                    key="/SystemLogin"
                    icon={<IconSystemLogin style={menuItemStyle} />}
                    onClick={colseNavbar}
                  >
                    <NavLink to="/SystemLogin">戰情登錄權限</NavLink>
                  </Menu.Item>

                  {/* <Menu.Item style={SubMenuItem} key="/WosPermissions" icon={<IconPermissions style={menuItemStyle}/>} onClick={colseNavbar}>
                    <NavLink to="/WosPermissions">報工作業權限</NavLink>
                  </Menu.Item> */}

                  {/* <Menu.Item style={SubMenuItem} key="/Companys" icon={<IconPermissions style={menuItemStyle}/>} onClick={colseNavbar}>
                    <NavLink to="/Companys">廠商基本資料</NavLink>
                  </Menu.Item> */}

                  {/* <Menu.Item style={SubMenuItem} key="/StopProject" icon={<IconPermissions style={menuItemStyle}/>} onClick={colseNavbar}>
                    <NavLink to="/StopProject">停工項目管理</NavLink>
                  </Menu.Item> */}

                  {/* <Menu.Item style={SubMenuItem} key="/BadProject" icon={<IconPermissions style={menuItemStyle}/>} onClick={colseNavbar}>
                    <NavLink to="/BadProject">不良項目管理</NavLink>
                  </Menu.Item> */}

                  {/* <Menu.Item style={SubMenuItem} key="/GoodProject" icon={<IconPermissions style={menuItemStyle}/>} onClick={colseNavbar}>
                    <NavLink to="/GoodProject">良品項目管理</NavLink>
                  </Menu.Item> */}

                  {/* <Menu.Item 
                    style={SubMenuItem} 
                    key="/FunctionProject" 
                    icon={<IconFunctionString style={{...menuItemStyle, marginRight: '5px'}} />} 
                    onClick={colseNavbar}
                  >
                    <NavLink to="/FunctionProject">功能字串管理</NavLink>
                  </Menu.Item> */}

                  {/* <Menu.Item style={SubMenuItem} key="/jjj" disabled icon={<IconReportSetting style={menuItemStyle}/>} >
                    <NavLink to="/jjj">報表週期設定</NavLink>
                  </Menu.Item> */}
                </SubMenu>
              </>
            ) : null}

            {/* 技術資料維護 */}
            {r_userState && r_userState.pdsID === "superman" ? (
              <SubMenu
                key="sub4"
                title="技術資料維護"
                icon={<IconSystemData style={style} />}
                style={{ flexGrow: "1" }}
              >
                {/* <Menu.Item style={SubMenuItem} key="/Basic" icon={<IconUsers style={menuItemStyle}/>} onClick={colseNavbar}>
                  <NavLink to="/Basic">產品資料管理</NavLink>
                </Menu.Item> */}

                <Menu.Item
                  style={SubMenuItem}
                  key="/Department"
                  icon={<IconUsers style={menuItemStyle} />}
                  onClick={colseNavbar}
                >
                  <NavLink to="/Department">部門資料管理</NavLink>
                </Menu.Item>

                <Menu.Item
                  style={SubMenuItem}
                  key="/JobTitle"
                  icon={
                    <IconJobTitle
                      style={{ ...menuItemStyle, marginRight: "5px" }}
                    />
                  }
                  onClick={colseNavbar}
                >
                  <NavLink to="/JobTitle">職稱資料管理</NavLink>
                </Menu.Item>

                <Menu.Item
                  style={SubMenuItem}
                  key="/PSdata"
                  icon={<IconPsData style={menuItemStyle} />}
                  onClick={colseNavbar}
                >
                  <NavLink to="/PSdata">車間資料管理</NavLink>
                </Menu.Item>

                <Menu.Item
                  style={SubMenuItem}
                  key="/Station"
                  icon={<IconStation style={menuItemStyle} />}
                  onClick={colseNavbar}
                >
                  <NavLink to="/Station">工站資料管理</NavLink>
                </Menu.Item>

                <Menu.Item
                  style={SubMenuItem}
                  key="/Line"
                  icon={<IconLine style={menuItemStyle} />}
                  onClick={colseNavbar}
                >
                  <NavLink to="/Line">工線資料管理</NavLink>
                </Menu.Item>

                {/* <Menu.Item style={SubMenuItem} key="/WareHouse" icon={<IconStation style={menuItemStyle}/>} onClick={colseNavbar}>
                  <NavLink to="/WareHouse">倉別資料管理</NavLink>
                </Menu.Item> */}
              </SubMenu>
            ) : null}

            <Menu.Divider />
          </CustomMenu>

          <CustomMenu
            selectable={false}
            style={menuBottomStyle}
            // expandIcon={() => {
            //   return <RightOutlined />
            // }}
          >
            <Menu.Item
              key="/userConfig"
              // className='footerItem1'
              onClick={editUser}
              icon={
                // <Avatar style={{ color: '#FEFEFE', backgroundColor: '#5995BC',width: '32px', height: '32px'  }}>
                <UserOutlined style={{ fontSize: "20px" }} />
                // </Avatar>
              }
            >
              <span style={{ fontSize: "18px", marginLeft: "5px" }}>
                個資管理
              </span>
            </Menu.Item>
            <Menu.Item
              key="version"
              style={{ display: "flex", alignItems: "center" }}
              // icon={<AlignLeftOutlined style={{fontSize: '20px'}} />}
              icon={
                r_isNavbar ? (
                  <RightOutlined onClick={is_navbar} />
                ) : (
                  <AlignLeftOutlined style={{ fontSize: "20px" }} />
                )
              }
              title={<span style={{ fontSize: "18px" }}>展開</span>}
              //title="展開"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Switch
                  style={{ marginLeft: "5px" }}
                  //defaultChecked={r_isNavbarInline ? false : true}
                  //defaultChecked={r_isNavbar ? true : false}
                  onChange={is_change_navbar_inline}
                />
                <span
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    marginLeft: "5px",
                  }}
                >
                  V1.7.6
                </span>
              </div>
              {/* <div style={{display: 'flex', alignItems: 'center'}}>
                <Switch
                  style={{width: '20px'}}
                  defaultChecked={r_isNavbarInline ? false : true}
                  onChange={is_change_navbar_inline}
                />
                <span style={{fontSize: '18px', marginLeft: '10px', marginRight: '10px'}}>V0.04</span>
              </div> */}
            </Menu.Item>
            <Menu.Item
              key="login"
              className="footerItem3"
              onClick={Logout}
              icon={<ExportOutlined width={20} style={{ fontSize: "20px" }} />}
            >
              <span
                onClick={Logout}
                style={{ fontSize: "18px", marginLeft: "5px" }}
              >
                退出
              </span>
              {/* <NavLink to="/Login">退出</NavLink> */}
            </Menu.Item>
          </CustomMenu>
        </div>
      </CustomSider>
      {s_showModal === true ? (
        <CustomModal
          width="60%"
          title="修改員工基本資料"
          visible={s_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => {
            set_s_showModal(false);
            set_s_staffData({});
          }}
          afterClose={() => set_s_staffData({})}
        >
          <ModalContainer
            set_s_showModal={set_s_showModal}
            s_staffData={s_staffData}
            s_isLock={s_isLock}
          />
        </CustomModal>
      ) : null}
    </>
  );
}
export default NavBar;
