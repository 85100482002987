import React, { useState, useEffect, useContext } from 'react';
import { MySubtitleH4 } from '../../../styled/publicTag.js';
import { DataContext } from '../index';

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';

const innerWidth = window.innerWidth;

const TB = () => {

  const [ s_tableData, set_s_tableData ] = useState([]);

  const { c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const tableColumns = [
    {
      title: '關鍵部品',
      dataIndex: 'KP',
      key: 'KP',
      align: 'center',
    },
    {
      title: '部品序號',
      dataIndex: 'SN',
      key: 'SN',
      align: 'center',
    },
    {
      title: '部品品名',
      dataIndex: 'TYPE',
      key: 'TYPE',
      align: 'center',
    },
    {
      title: '部品料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
    },
  ];

  useEffect(() => {

    if(Object.keys(c_lightData).length === 0) return;

    if(Object.keys(c_lightData.KP).length === 0) return;
    

    // 建立長度為3的陣列 ==> [...Array(3).keys()]
    const newArray = [...Array(30)].map((item, index) => {
      return {
        'KP': `KP${index+1}`,
        'SN': c_lightData.KP[index]?.SN,
        'TYPE': c_lightData.KP[index]?.TYPE,
        'PN': c_lightData.KP[index]?.PN,
      }
    });
    // console.log('newArray = ', newArray)



    set_s_tableData(newArray);
  }, [c_lightData]);

  return (
    <>
    <Title title='生產條碼查詢明細' >
      <div />
    </Title>
    <CustomTable 
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ x: 'max-content' }}
        columns={tableColumns}
        dataSource={s_tableData.length > 0 && s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
    </>
  )
};

export default TB;