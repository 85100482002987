import styled from "styled-components";
import { fontSize } from "./publicTag";

const WOIBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

const WOIMainBoxItem = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 30%;
  height: 110px;
  border-radius: 10px;
  border: 0.1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.cardBg};
  margin: 5px 0px;
  white-space: nowrap;
`;

const WOIMainBoxItemHeader = styled.div`
  height: 10%;
  width: 100%;
  background-color: rgb(113, 175, 248);
  border-radius: 10px 10px 0 0;
`;

const WOIMainBoxItemTitle = styled.div`
  height: 45%;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(196, 196, 196, 0.4);
  color: ${({ theme }) => theme.text};
  padding: 5px 20px;
  font-size: ${fontSize.h1};
  font-weight: 400;
`;
const WOIMainBoxItemContent = styled.div`
  height: 45%;
  width: 100%;
  padding: 5px 20px;
  font-size: ${fontSize.h2};
  white-space: nowrap; /*//强制文本在一行内输出 */
  overflow: hidden; /*隐藏溢出部分*/
  text-overflow: ellipsis; /*//對溢出部分加上...*/
`;
const WOIStart = styled.div`
  height: 45%;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  background-color: rgb(113, 175, 248);
  color: ${({ theme }) => theme.retext};
  padding: 5px 20px;
  font-size: ${fontSize.h1};
  font-weight: 400;
`;
const WOI = {
  WOIBox,
  WOIMainBoxItem,
  WOIMainBoxItemHeader,
  WOIMainBoxItemTitle,
  WOIMainBoxItemContent,
  WOIStart,
};

export default WOI;
