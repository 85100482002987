import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, Row, Col, Space, Divider } from "antd";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import Cookies from "universal-cookie";
import moment from "moment"
import "./index.css";

const FormTemplate = ({ thisData, pcid, bdm71 }) => {
  const wsData = [
    "Pre-test",
    `Hi-Pot \b test`,
    "Final test",
    "Visual \b check",
    "Burn-in",
    "OS Check",
    "OQC",
    "FQC",
    "",
    "Burn-in \b time",
  ];

  return (
    <div className="flex-center pointer-box" key={Math.random() * 1000}>
      <div className="w-400p border-3-t border-3-b border-3-r border-3-l">
        <div className="w-100 flex-center font-24 border-3-b">
          生產流程管制卡
        </div>
        <div className="flex-center">
          <div className=" flex-c w-70">
            <div className="flex-center ">
              <div className="flex-center h-25 w-25 font-17 border-r border-b">
                工單
              </div>
              <div className="flex-center h-25 w-75 border-r border-b">
                {thisData.woN}
              </div>
            </div>
            <div className="flex-center ">
              <div className="flex-center h-25 w-25 font-17 border-r border-b">
                料號
              </div>
              <div className="flex-center h-25 w-75 border-r border-b">
                {thisData.PN}
              </div>
            </div>
            <div className="flex-center ">
              <div className="flex-center h-25 w-25 font-17 border-r border-b">
                數量
              </div>
              <div className="flex-center h-25 w-75 border-r border-b">
                {thisData.planQty}
              </div>
            </div>
            <div className="flex-center">
              <div className="flex-center h-25 w-25 font-17 border-r border-3-b">
                日期
              </div>
              <div className="flex-center h-25 w-75 border-r border-3-b">
                {moment(thisData.openT).format("YYYY-MM-DD")}
              </div>
            </div>
          </div>
          <div className="flex-c flex-center h-100 w-30 border-3-b">
            <QRCode
              id={thisData.UUID}
              value={pcid} //value引數為生成二維碼的連結 我這裡是由後端返回
              size={60} //二維碼的寬高尺寸
              fgColor="#000000" //二維碼的顏色
            />
            <div className="font-14 mt-5">{pcid}</div>
          </div>
        </div>
        <div className="flex-center w-100 font-14 border-b">
          Operator Countersign
        </div>
        <Row className="font-11">
          <Col span={4} className="flex-center border-r border-b h-23">
            站別
          </Col>
          {bdm71.map(
            (x, i) =>
              i < 5 && (
                <Col
                  span={4}
                  key={i}
                  className={`flex-center  border-b h-23 ${i !== 4 && "border-r"}`}
                >
                  {x.pos}
                </Col>
              )
          )}
          <Col span={4} className="flex-center border-r border-b h-23">
            人員
          </Col>
          {bdm71.map(
            (x, i) =>
              i < 5 && (
                <Col
                  span={4}
                  key={i}
                  className={`flex-center  border-b h-23 ${i !== 4 && "border-r"}`}
                ></Col>
              )
          )}
          <Col span={4} className="flex-center border-r border-b h-23">
            站別
          </Col>
          {bdm71.map(
            (x, i) =>
              i >= 5 && (
                <Col
                  span={4}
                  key={i}
                  className={`flex-center  border-b h-23 ${i !== 9 && "border-r"}`}
                >
                  {x.pos}
                </Col>
              )
          )}
          <Col span={4} className="flex-center border-r border-b h-23">
            人員
          </Col>
          {bdm71.map(
            (x, i) =>
              i >= 5 && (
                <Col
                  span={4}
                  key={i}
                  className={`flex-center  border-b h-23 ${i !== 9 && "border-r"}`}
                ></Col>
              )
          )}
        </Row>
        <div className="flex-center flex-wrap w-100 border-3-t">
          <Row className="w-100">
            <Col span={4} className="flex-center border-r border-b h-23">
              站別
            </Col>
            <Col span={4} className="flex-center border-r border-b h-23">
              1<sup>st</sup>
            </Col>
            <Col span={4} className="flex-center border-r border-b h-23">
              2<sup>nd</sup>
            </Col>
            <Col
              span={4}
              className="flex-center border-l border-r border-b h-23"
            >
              站別
            </Col>
            <Col span={4} className="flex-center border-r border-b h-23">
              1<sup>st</sup>
            </Col>
            <Col span={4} className="flex-center border-b h-23">
              2<sup>nd</sup>
            </Col>
          </Row>
          <Row className="w-100">
            <Col span={12}>
              {wsData.map(
                (x, i) =>
                  i < 5 && (
                    <Row className="w-100 h-50" key={i}>
                      <Col
                        span={8}
                        className="flex-center border-r border-b text-align-center"
                      >
                        {x}
                      </Col>
                      <Col span={16}>
                        <div className="flex-center flex-wrap">
                          <div className="flex-center h-25 w-50 border-r border-b color-black-2">
                            OK
                          </div>
                          <div className="flex-center h-25 w-50 border-r border-b color-black-2">
                            NG
                          </div>
                          <div className="flex-center h-25 w-50 border-r border-b color-black-2">
                            OK
                          </div>
                          <div className="flex-center h-25 w-50 border-r border-b color-black-2">
                            NG
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
              )}
            </Col>
            <Col span={12}>
              {wsData.map(
                (x, i) =>
                  i >= 5 && (
                    <Row className="w-100 h-50" key={i}>
                      <Col
                        span={8}
                        className="flex-center border-l border-r border-b text-align-center"
                      >
                        {x}
                      </Col>
                      <Col span={16}>
                        <div className="flex-center flex-wrap">
                          <div className="flex-center h-25 w-50 border-r border-b color-black-2">
                            OK
                          </div>
                          <div className="flex-center h-25 w-50  border-b color-black-2">
                            NG
                          </div>
                          <div className="flex-center h-25 w-50 border-r border-b color-black-2">
                            OK
                          </div>
                          <div className="flex-center h-25 w-50  border-b color-black-2">
                            NG
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className="border-2-l-d mr-10 ml-10 h-538"></div>
      <div
        className="w-400p border-3-t border-3-b border-3-r border-3-l"
        // style={{ backgroundColor: "#9caae9" }}
      >
        <div className="w-100 flex-center font-24 border-3-b">不良維修紀錄</div>
        <Row className="w-100 border-3-b">
          <Col
            span={4}
            className="text-align-center font-17 h-60 flex-center border-r"
          >
            不良
            <br />
            站別
          </Col>
          <Col span={4} className="text-align-center font-17 h-60 border-r">
            <div className="h-30 flex-center border-b">
              1<sup>st</sup>
            </div>
          </Col>
          <Col span={4} className="text-align-center font-17 h-60 border-r">
            <div className="h-30 flex-center border-b">
              2<sup>nd</sup>
            </div>
          </Col>
          <Col span={4} className="text-align-center font-17 h-60 border-r">
            <div className="h-30 flex-center border-b">
              3<sup>rd</sup>
            </div>
          </Col>
          <Col span={4} className="text-align-center font-17 h-60 border-r">
            <div className="h-30 flex-center border-b">
              4<sup>th</sup>
            </div>
          </Col>
          <Col span={4} className="text-align-center font-17 h-60 border-r">
            <div className="h-30 flex-center border-b">
              5<sup>th</sup>
            </div>
          </Col>
        </Row>
        <div className="h-30 border-b flex-center">不良原因</div>
        <Row className="w-100 border-3-b">
          <Col span={4} className="flex-center border-r border-b h-50">
            1<sup>st</sup>
          </Col>
          <Col span={16} className="border-r border-b h-50"></Col>
          <Col span={4} className=" border-b h-50">
            <div className="h-25 flex-center border-b">檢驗者</div>
          </Col>
          <Col span={4} className="flex-center border-r border-b h-50">
            2<sup>nd</sup>
          </Col>
          <Col span={16} className="border-r border-b h-50"></Col>
          <Col span={4} className=" border-b h-50">
            <div className="h-25 flex-center border-b">檢驗者</div>
          </Col>
          <Col span={4} className="flex-center border-r border-b h-50">
            3<sup>rd</sup>
          </Col>
          <Col span={16} className="border-r border-b h-50"></Col>
          <Col span={4} className=" border-b h-50">
            <div className="h-25 flex-center border-b">檢驗者</div>
          </Col>
        </Row>
        <div className="h-30 border-b flex-center">維修回報</div>
        <Row className="w-100 border-3-b">
          <Col span={4} className="flex-center border-r border-b h-50">
            1<sup>st</sup>
          </Col>
          <Col span={16} className="border-r border-b h-50"></Col>
          <Col span={4} className="border-b h-50">
            <div className="h-25 flex-center border-b">檢驗者</div>
          </Col>
          <Col span={4} className="flex-center border-r border-b h-50">
            2<sup>nd</sup>
          </Col>
          <Col span={16} className="border-r border-b h-50"></Col>
          <Col span={4} className=" border-b h-50">
            <div className="h-25 flex-center border-b">檢驗者</div>
          </Col>
          <Col span={4} className="flex-center border-r border-b h-50">
            3<sup>rd</sup>
          </Col>
          <Col span={16} className="border-r border-b h-50"></Col>
          <Col span={4} className=" border-b h-50">
            <div className="h-25 flex-center border-b">檢驗者</div>
          </Col>
        </Row>
        <Row className="w-100 h-60">
          <Col span={4} className="border-r flex-center">
            Remark
          </Col>
          <Col span={20}></Col>
        </Row>
      </div>
    </div>
  );
};

const PrintFormEle = ({ className, setIsModal, thisData, bdm71, pcids }) => {
  const printRef = useRef("");
  const [isPrint, setIsPrint] = useState(true);
  const [printNumObj, setPrintNumObj] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    // content: () => CreatePrintNumObj(printNum),
    bodyClass: "print-style",
    pageStyle: `
    body{
      counter-reset: page-number;  
    }
    @page {
      size: A5 landscape;
      margin-top:10px;
      margin-bottom:0px;
    }
    .pointer-box {
      page-break-before: always;
      page-break-inside: avoid;
      position: relative;
    }

    .Wraper{
      page-break-inside: avoid;
      position: relative;
    }

  `,
  });

  useEffect(() => {
    if (!isPrint) {
      setTimeout(() => {
        setIsPrint(true);
      }, [500]);
    }
  }, [isPrint]);

  useEffect(() => {
    const obj = pcids.map((x) => {
      return (
        <FormTemplate
          thisData={thisData}
          bdm71={bdm71}
          pcid={x.pcid}
          key={Math.random() * 1000}
        />
      );
    });
    setPrintNumObj(obj);
  }, []);

  return (
    <div className={className}>
      <div className="pointer-box print">
        <FormTemplate thisData={thisData} bdm71={bdm71} pcid={pcids[0].pcid} />
      </div>
      <div className="print hidden" ref={printRef}>
        {printNumObj}
      </div>
      <Divider />
      <div className="w-100 align-r">
        <Space>
          <Button type="primary" size="large" onClick={handlePrint}>
            列印
          </Button>
        </Space>
      </div>
    </div>
  );
};

const PrintForm = styled(PrintFormEle)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .hidden {
    display: none;
  }
  .pointer-box {
    /* width: 794px; */
    /* height: 650px; */
    /* background-color: #f5e0e0; */
    /* border: 1px solid #000; */
  }
  .ant-table-cell {
    font-size: 12px;
    padding: 4px;
  }
  .ant-table {
    border: none;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #dc10dc00;
  }
  .ant-table-cell {
    background-color: #fafafa;
  }
  .align-r {
    text-align: right;
    margin-right: 15px;
  }
  .w-100 {
    width: 100%;
  }
  .flex-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export default PrintForm;
