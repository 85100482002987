import React, { useState, useEffect } from 'react';

import { Input, Button } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';


const Header = ({ handleClose }) => {

  return (
    <div style={{float: 'right'}}>
      <Button
        type='danger'
        size='large'
        style={{marginLeft: '10px', borderRadius: '5px'}} 
        icon={<FullscreenExitOutlined />} 
        onClick={handleClose} 
      >
        隱藏明細檔
      </Button>
      
    </div>
  )
};

export default Header;