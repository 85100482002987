import React, { useState, useEffect } from "react";
import { Row, Col, Progress, Tabs, Divider, Table } from "antd";
import {
  MyComponent,
  MySubtitleH4,
  MyMainColorH3,
} from "../../styled/publicTag";
import moment from "moment";
import timeGap from "../../utility/timeGap";
import MyIcon from "../Icon/pageIcon";
import { useTheme } from "styled-components";
import checkUndefined from "../../utility/checkUndefined";
import { formatFloat } from "../../utility/formatFloat"

const Productivity = ({ data, multiInfo }) => {
  const { TabPane } = Tabs;

  const theme = useTheme();

  const tableCol = [
    { title: "工位", dataIndex: "note", width: "80%" },
    { title: "通過數量", dataIndex: "amount", align: "center" },
  ]

  const [lineChartStatus, setLineChartStatus] = useState("active");

  //整理過後的時間
  const [productivityData, setProductivityData] = useState({
    openTime: "",
    pdtRate: 0.0,
    allTime: "",
    prepareTime: "0天0時0分",
    prepareToOpen: "0天0時0分",
  });

  const [multi, setMulti] = useState({
    isButton: false,
    isOpen: false,
    data: [{}, {}, {}],
  });

  const [tableData, setTableData] = useState([])

  // 生產追蹤專用
  useEffect(() => {
    setTableData(Array.isArray(data.trace) && data.trace.map(x => {
      if (x.sopno !== 0) {
        return { ...x, key: x.sopno }
      }
    }))
  }, [data])

  useEffect(() => {
    let timer = "";
    if (
      data !== undefined &&
      data !== null &&
      Object.getOwnPropertyNames(data).length !== 0
    ) {
      data.lifeF !== "2"
        ? setLineChartStatus("normal")
        : setLineChartStatus("active");
      //如果為停工或是在復工 每分鐘增加分鐘數
      if (data.lifeF === "2" || data.lifeF === "4") {
        setProductivityData({
          prepareTime: data.prepareTime,
          openTime: checkUndefined(data.openTime),
          pdtRate: data.pdtRate,
          prepareToOpen: Array.isArray(data.prepareToOpen)
            ? `${data.prepareToOpen[0]}天${data.prepareToOpen[1]}時${data.prepareToOpen[2]}分`
            : "0天0時0分",
          allTime: timeGap(new Date(moment()), new Date(data.openT)),
        });

        timer = setInterval(() => {
          setProductivityData({
            prepareTime: data.prepareTime,
            openTime: checkUndefined(data.openTime),
            pdtRate: data.pdtRate,
            prepareToOpen: Array.isArray(data.prepareToOpen)
              ? `${data.prepareToOpen[0]}天${data.prepareToOpen[1]}時${data.prepareToOpen[2]}分`
              : "0天0時0分",
            allTime: timeGap(new Date(moment()), new Date(data.openT)),
          });
        }, 60000);
      } else {
        //如果為停工或是在復工 如果不是 直接塞入
        setProductivityData({
          prepareTime: data.prepareTime,
          openTime: checkUndefined(data.openTime),
          pdtRate: data.pdtRate,
          allTime: Array.isArray(data.totalDay)
            ? `${data.totalDay[0]}天${data.totalDay[1]}小時${data.totalDay[2]}分`
            : "0天0時0分",
          prepareToOpen: Array.isArray(data.prepareToOpen)
            ? `${data.prepareToOpen[0]}天${data.prepareToOpen[1]}小時${data.prepareToOpen[2]}分`
            : "0天0時0分",
        });
      }
      // 如果沒有資料
    } else {
      clearInterval(timer);
      setLineChartStatus("normal");
      setProductivityData({
        openTime: "暫無數據",
        pdtRate: 0.0,
        allTime: "暫無數據",
        prepareTime: "暫無數據",
        prepareToOpen: "暫無數據",
      });
    }
    return () => {
      clearInterval(timer);
    };
  }, [data]);

  useEffect(() => {
    if (multiInfo.length > 0) {
      setMulti({
        ...multi,
        isButton: false,
        data: multiInfo,
      });
    } else {
      setMulti({
        ...multi,
        isButton: true,
        data: [],
      });
    }
  }, [multiInfo]);

  const lineBoxStyle = {
    textAlign: "center",
    margin: "0px",
    width: "100%",
  };

  const boxStyle = {
    display: "flex",
    flexDirection: "column ",
    justifyContent: "center",
    alignItems: "center",
  };

  const boxRowStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  };

  const TabStyle = {
    height: "287px",
  };

  return (
    <MyComponent className="woc-productivity" style={TabStyle}>
      <Tabs size="large">
        <TabPane tab="生產計時" key="1" style={{ padding: "15px 0px" }}>
          <Row justify="space-around" align="middle">
            <Col span={6}>
              <div style={boxStyle}>
                <div style={boxRowStyle}>
                  <span style={boxRowStyle}>
                    <MyIcon.IconOpenT />
                  </span>
                  <MySubtitleH4 style={{ margin: "0px" }}>
                    開始準備時間
                  </MySubtitleH4>
                </div>
                <MyMainColorH3>{productivityData.prepareTime}</MyMainColorH3>
              </div>
            </Col>
            <Col span={6}>
              <div style={boxStyle}>
                <div style={boxRowStyle}>
                  <span style={boxRowStyle}>
                    <MyIcon.IconMhc />
                  </span>
                  <MySubtitleH4 style={{ margin: "0px" }}>
                    開始投產時間
                  </MySubtitleH4>
                </div>
                <MyMainColorH3>{productivityData.openTime}</MyMainColorH3>
              </div>
            </Col>
            <Col span={6}>
              <div style={boxStyle}>
                <div style={boxRowStyle}>
                  <span style={boxRowStyle}>
                    <MyIcon.IconStandbyTime />
                  </span>
                  <MySubtitleH4 style={{ margin: "0px" }}>
                    生產準備時間
                  </MySubtitleH4>
                </div>
                <MyMainColorH3>{productivityData.prepareToOpen}</MyMainColorH3>
              </div>
            </Col>
            <Col span={6}>
              <div style={boxStyle}>
                <div style={boxRowStyle}>
                  <span style={boxRowStyle}>
                    <MyIcon.IconProccessTime />
                  </span>
                  <MySubtitleH4 style={{ margin: "0px" }}>
                    累積生產時間
                  </MySubtitleH4>
                </div>
                <MyMainColorH3>{productivityData.allTime}</MyMainColorH3>
              </div>
            </Col>
            <Divider />
            <Col span={24}>
              <div style={lineBoxStyle}>
                <Row align="middle" justify="center">
                  <Col span={23}>
                    <div style={boxRowStyle}>
                      <div style={boxRowStyle}>
                        <span style={boxRowStyle}>
                          <MyIcon.IconChart />
                        </span>
                        <MySubtitleH4>生產達成率</MySubtitleH4>
                      </div>
                      <div style={{ display: "inline-block", width: "100%" }}>
                        <Progress
                          strokeWidth={25}
                          status={lineChartStatus}
                          strokeColor={{
                            "0%": theme.mainColor,
                            "100%": theme.Color2,
                          }}
                          percent={formatFloat(productivityData.pdtRate, 0)}
                        />
                      </div>
                      <MySubtitleH4>{`${formatFloat(productivityData.pdtRate, 0)}%`}</MySubtitleH4>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane TabPane tab="生產追蹤" key="3" style={{ padding: "15px" }}>
          <Row justify="start" align="middle" gutter={[48, 0]}>
            <Col span={24}>
              <Table
                style={{ height: "165px" }}
                size="small"
                columns={tableCol}
                dataSource={tableData}
                scroll={{ y: 135 }}
              />
            </Col>
            {/* <Col span={4}>
              <div style={boxStyle}>
                <MySubtitleH4>完成ASSY</MySubtitleH4>
                <MyMainColorH3>{checkUndefined(data.assy)}</MyMainColorH3>
              </div>
            </Col>
            <Col span={4}>
              <div style={boxStyle}>
                <MySubtitleH4>完成INSP</MySubtitleH4>
                <MyMainColorH3>{checkUndefined(data.insp)}</MyMainColorH3>
              </div>
            </Col>
            <Col span={4}>
              <div style={boxStyle}>
                <MySubtitleH4>完成PACK</MySubtitleH4>
                <MyMainColorH3>{checkUndefined(data.pack)}</MyMainColorH3>
              </div>
            </Col> */}
          </Row>
        </TabPane>
        {/* <TabPane tab="共模總覽" key="3" disabled={multi.isButton}>
          <div style={{ height: "200px", overflowY: "scroll" }}>
            <Row justify="space-between" align="middle">
              {multi.data.map((item, index) => {
                return (
                  <Col key={item.woN + index} span={24}>
                    <Row justify="space-between" align="middle">
                      <Col span={8}>
                        <div style={boxStyle}>
                          <MySubtitleH4>{index===0 ? `主單單號`:`副單單號-${index}`}</MySubtitleH4>
                          <MyMainColorH3>{item.woN}</MyMainColorH3>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div style={boxStyle}>
                          <MySubtitleH4>料號</MySubtitleH4>
                          <MyMainColorH3>{item.PN}</MyMainColorH3>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div style={boxStyle}>
                          <MySubtitleH4>品名</MySubtitleH4>
                          <MyMainColorH3>{item.pdtNM}</MyMainColorH3>
                        </div>
                      </Col>
                    </Row>
                    <Divider style={{margin:"6px 0px"}}/>
                  </Col>
                );
              })}
            </Row>
          </div>
        </TabPane> */}
      </Tabs>
    </MyComponent>
  );
};

export default Productivity;
