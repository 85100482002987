import React, { useState, useEffect, useRef, useContext } from 'react';
import { Input, Spin } from 'antd';
import Workbook from 'react-excel-workbook';

// import { CloseCircleOutlined, CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';

import { MySubtitleH4 } from '../../../styled/publicTag.js';

import { DataContext } from '../index';

import { getBarcode } from '../../../services/api';

import { IconExcel } from '../../../components/Icon/index';

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';
import CustomModal from '../../../components/antdCustom/CustomModal';

import ModalContainer from './ModalContainer';

const innerWidth = window.innerWidth;

const HD = () => {

  let countRef = useRef(null);

  const [scrollY, setScrollY] = useState("");

  const [ s_isLoading, set_s_isLoading ] = useState(false);

  const [s_Excel, set_s_Excel] = useState([]);

  const [ExcelColumns, set_ExcelColumns] = useState([]);

  const [ s_tableData, set_s_tableData ] = useState([]);

  const [ is_showModal, set_is_showModal ] = useState(false);

  const [ s_searchValue, set_s_searchValue ] = useState('');

  const { c_lightData, set_c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const call_getBarcode = async () => {
    set_s_isLoading(true)
    const res = await getBarcode({
      'woN': s_searchValue
    });

    console.log('res = ', res)

    res.status === true ? set_s_tableData(res.data) : set_s_tableData([]);
    set_s_isLoading(false)
  };

  // 取得全部表頭資料
  useEffect(() => {
    
    call_getBarcode()
  }, []);

  // 即時計算table高度
  const getTableScroll = ({ extraHeight, ref } = {}) => {
    if (typeof extraHeight == "undefined") {
      //  默认底部分页64 + 边距10
      extraHeight = 64
    }
    let tHeader = null
    if (ref && ref.current) {
      tHeader = ref.current.getElementsByClassName("ant-table-thead")[0]
    } else {
      tHeader = document.getElementsByClassName("ant-table-thead")[0]
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0
    if (tHeader) {
      tHeaderBottom = tHeader.getBoundingClientRect().bottom
    }
    // 窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    let tmpvh = Object.keys(c_lightData).length > 0 ? '50vh' : '100vh'

    let height = `calc(${tmpvh} - ${tHeaderBottom + extraHeight}px)`
    // 空数据的时候表格高度保持不变,暂无数据提示文本图片居中
    if (ref && ref.current) {
      let placeholder = ref.current.getElementsByClassName('ant-table-placeholder')[0]
      if (placeholder) {
        placeholder.style.height = height
        placeholder.style.display = "flex"
        placeholder.style.alignItems = "center"
        placeholder.style.justifyContent = "center"
      }
    }
    console.log('height = ', height)
    return height
  }

  const tableColumns = [
    {
      title: '工單號',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${130}px` : `${150}px`,
    },
    {
      title: '成品料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${130}px` : `${150}px`,
    },
    {
      title: '成品品名',
      dataIndex: 'Model',
      key: 'Model',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`,
    },
    {
      title: '出貨序號',
      dataIndex: 'SSN',
      key: 'SSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${130}px` : `${150}px`,
    },
    {
      title: '生產序號',
      dataIndex: 'MSN',
      key: 'MSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${130}px` : `${150}px`,
    },
    {
      title: '箱號',
      dataIndex: 'Pallet_No',
      key: 'Pallet_No',
      align: 'center',
      width: innerWidth <= 1440 ? `${130}px` : `${150}px`,
    },
    // {
    //   title: '主機板料號',
    //   dataIndex: 'MB_PN',
    //   key: 'MB_PN',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${130}px` : `${150}px`,
    // },
  ];

  const handleClick = () => {
    set_is_showModal(true)
  }

  
  // 取得 Excel 表頭
  useEffect(() => {
    
    const HDArray = [
      {
        title: 'Model',
        key: 'Model'
      },
      {
        title: 'SSN',
        key: 'SSN'
      },
      {
        title: 'MB_Model',
        key: 'MB_Model'
      },
      {
        title: 'MB_SN',
        key: 'MB_SN'
      },
      {
        title: 'MB_PN',
        key: 'MB_PN'
      }
    ]
    
    const dataArray = [...Array(30)].map((item, index) => {
      return [
        {
          title: `KP_TYPE${index+1}`,
          key: `KP_TYPE${index+1}`,
        },
        {
          title: `KP_SN${index+1}`,
          key: `KP_SN${index+1}`,
        },
        {
          title: `KP_PN${index+1}`,
          key:`KP_PN${index+1}`,
        }
      ]
    });

    const tmpArray = [].concat.apply([], HDArray.concat(dataArray));

    set_ExcelColumns(tmpArray)
  }, []);

  // 取得 Excel 資料
  useEffect(() => {
    // if(Object.keys(c_lightData).length === 0) return;

    const tmpArray = s_tableData.map((item, index) => {

      const dataArray = [...Array(30)].map((item1, index1) => {
        return {
          [`KP_TYPE${index1+1}`]: item.KP[index1] ? item.KP[index1].TYPE : "",
          [`KP_SN${index1+1}`]: item.KP[index1] ? item.KP[index1].SN : "",
          [`KP_PN${index1+1}`]: item.KP[index1] ? item.KP[index1].PN : "",
        }
      })

      const bbb = {}

      dataArray.forEach(element => {
        Object.keys(element).forEach((qqq) => {
          // console.log('qqq = ',qqq)
          bbb[qqq] = element[qqq]
        });
      });

      return {
        'Model': item.Model,
        'SSN': item.SSN,
        'MB_Model': item.MB_Model,
        'MB_SN': item.MB_SN,
        'MB_PN': item.MB_PN,
        ...bbb,
      }
    })

    console.log('tmpArray = ', tmpArray)


    set_s_Excel(tmpArray);

  }, [s_tableData])

  // 表身有資料時計算高度
  useEffect(() => {
    setScrollY(getTableScroll({ ref: countRef }))
  }, [c_lightData]);

  const onSearch = () => {
    
    call_getBarcode()
    set_c_lightData({})
  }

  return (
    <Spin size='large' spinning={s_isLoading}>
      <Title title='生產條碼查詢'>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Input.Search 
            className='Input' 
            size='large' 
            placeholder='請輸入工單號' 
            onChange={e => {
              set_s_searchValue(e.target.value)
            }} 
            onSearch={onSearch} 
            enterButton 
          />
          <span style={{height: '38px', cursor: 'pointer'}}>
            <IconExcel 
              style={{width: '30px', height: '38px', marginLeft: '10px', marginRight: '5px'}} 
              onClick={handleClick}
            />
            {/* <Workbook 
              filename={`生產條碼查詢.xlsx`} 
              element={
                <IconExcel 
                  style={{width: '30px', height: '38px', marginLeft: '10px', marginRight: '5px'}} 
                />
              }>
                <Workbook.Sheet data={s_Excel} name="Sheet A">
                  {ExcelColumns.map((item, index) => {
                    return(
                      <Workbook.Column key={index} label={item.title} value={item.key} />
                    )
                  })}
                </Workbook.Sheet>
              </Workbook> */}
          </span>

        </div>
      </Title>

      <CustomTable
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ x: 'max-content', y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        rowClassName={(record) => {
          const rowKey = record.MSN;
          const dataKey = c_lightData.MSN;
          return rowKey === dataKey ? "clickRowStyle" : "";
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              const rowKey = record.MSN;
              const dataKey = c_lightData.MSN;
              if (rowKey === dataKey) {
                set_c_lightData({})
              } else {
                set_c_lightData(record)
              }
            }
          }
        }}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {is_showModal ? (
        <CustomModal 
          width='60%' 
          title="生產條碼查詢列印" 
          visible={is_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_is_showModal(false)}
          afterClose={() => {
            // set_s_PSData({});
            // call_getAllPS();
          }}
        >
          <ModalContainer s_tableData={s_tableData} set_is_showModal={set_is_showModal} />
        </CustomModal>
       ) : null}
    </Spin>
  )
};

export default HD;