import { instance, conn, url } from "../handlerAPI";

// /getAllSophd 取得所有SOP表頭
export const getAllSophd = (_params) => {
  return instance.get(`${conn}://${url}/api/getAllSophd`, { params: _params });
};

// addSophd 新增SOP表頭
export const addSophd = (data) => {
  return instance.post(
    `${conn}://${url}/api/addSophd`,
    data
  );
};

// modifySophd 編輯SOP表頭
export const modifySophd = (data) => {
  return instance.post(`${conn}://${url}/api/modifySophd`, data);
};

// /deleteSop 刪除SOP表頭
export const deleteSophd = (data) => {
  return instance.post(`${conn}://${url}/api/deleteSophd`, data);
};

//-------------------------------------------------------------------
//-------------------------------------------------------------------
//-------------------------------------------------------------------

// 表身 get
export const getAllSoptb = (_params) => {
  console.log('_params = ', _params)
  return instance.get(`${conn}://${url}/api/getAllSoptb`, { params: _params });
};

// 表身 post
export const modifySoptb = (data) => {
  return instance.post(`${conn}://${url}/api/modifySoptb`, data);
};

//-------------------------------------------------------------------
//-------------------------------------------------------------------
//-------------------------------------------------------------------

export const getAllSoprec = (_params) => {
  return instance.get(`${conn}://${url}/api/getAllSoprec`, { params: _params });
};

export const modifySoprec = (data) => {
  return instance.post(`${conn}://${url}/api/modifySoprec`, data);
};


// 條碼
export const printPosition = (_params) => {
  return instance.get(`${conn}://${url}/api/printPosition`, { params: _params });
}

// copySop 據此條目創建
export const copySop = (data) => {
  return instance.post(`${conn}://${url}/api/copySop`, data);
};
