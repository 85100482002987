import { useState, useEffect } from 'react';
import { message } from 'antd';

import { MySubtitleH4 } from '../../../styled/publicTag';

import { getPcidDetail } from '../../../services/api';

import CustomTable from '../../../components/antdCustom/CustomTable';

const innerWidth = window.innerWidth;

const DrawerModal = ({s_pcid}) => {

  console.log('s_pcid = ', s_pcid)

  const [s_tableData, set_s_tableData] = useState([]);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const [scrollY, setScrollY] = useState("")

  useEffect(() => {

    const call_getPcidDetail = async () => {
      const res = await getPcidDetail({pcid: s_pcid});

      if(res.status === true) {
        console.log(res)
        set_s_tableData(res.data);
        message.success(res.msg)
      } else {
        message.error(res.msg)
      }
    }

    call_getPcidDetail()

  }, []);

  const tableColumns = [
    {
      title: "生產序號",
      dataIndex: "pcid",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    },
    {
      title: "掃碼內容",
      dataIndex: "itemcode",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${250}px`,
    },
    {
      title: "項目名稱",
      dataIndex: "itemNM",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${200}px`,
    },
    {
      title: "掃碼項目",
      dataIndex: "itemanchor",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${250}px`,
    },
   {
      title: "掃碼時間",
      dataIndex: "updtTstr",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    },
  ]

  return (
    <CustomTable
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ x: 'max-content', y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          defaultPageSize: 30,
          showSizeChanger: true,
          // pageSize: pageInfo.pageSize,
          pageSize: 30,
          // pageSizeOptions: ["10", "20", "30"],
          pageSizeOptions: [10, 20, 30, 40],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
  )
};

export default DrawerModal;