import React from 'react';

import { IconPen, IconGarbage } from '../Icon/newicon';

const OperateArea = ({ onEditClick, onDeleteClick, editDisabled, deleteDisabled}) => {

  // 不可編輯時兩個 Icon 滑鼠滑過的樣式
  const editCursor = editDisabled ? 'not-allowed' : 'pointer';
  const deleteCursor = deleteDisabled ? 'not-allowed' : 'pointer';

  // 不可編輯時兩個 Icon 的顏色
  const editColor = editDisabled ? '#c9c3c3' : 'rgb(97, 87, 97)';
  const deleteColor = deleteDisabled ? '#c9c3c3' : 'rgb(97, 87, 97)';

  return (
    <div style={{display: 'flex',justifyContent: 'center', fontSize: '24px'}}>
      <div style={{cursor: editCursor, color: editColor}}>
        <IconPen 
          style={{marginRight: '5px'}} 
          onClick={event => {
            event.stopPropagation();
            if(!editDisabled) onEditClick()
          }}
        />
      </div>
      <div style={{cursor: deleteCursor, color: deleteColor}}>
        <IconGarbage 
          onClick={event => {
            event.stopPropagation();
            if(!deleteDisabled) onDeleteClick();
          }}
        /> 
      </div>
    </div>
  )
};

export default OperateArea;